import React from "react";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import clsx from "clsx";

const BtnBack = ({ destination }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    destination ? navigate(destination) : navigate(-1);
  };
  return (
    <button className={clsx("btn-go-back")} onClick={handleClick}>
      <MdKeyboardArrowLeft className={clsx("w-5 h-5")} />
      <p className="text-sm">戻る</p>
    </button>
  );
};

export default BtnBack;
