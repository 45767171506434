import React, { useState, useRef, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../../package/firebase";
import "../../styles/MyCarousel.css";
import carousel1 from "../../assets/carousel-1.jpg";
import carousel2 from "../../assets/carousel-2.jpg";
import carousel3 from "../../assets/carousel-3.jpg";
import carousel4 from "../../assets/carousel-4.jpg";
import carousel5 from "../../assets/carousel-5.jpg";
import { loginAndYoutube } from "../../actions/auth";
import { ERROR_MSG_PREFIX } from "../../const/error-message";

const MyCarousel = (props) => {
  const images = [
    { url: carousel1, link: "https://revolve-yokohama.com" },
    { url: carousel2, link: "https://x.com/JGS_oripa_card" },
    { url: carousel3, link: "https://example.com" },
    { url: carousel4, link: "https://jgs-yokohama.com" },
    { url: carousel5, link: "https://www.instagram.com/revolve_jgs/#" },
  ];
  const [user] = useAuthState(fireauth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const viewportRef = useRef(null);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const viewport = viewportRef.current;
    if (viewport) {
      viewport.style.transform = `translateX(-${currentIndex * 100}%)`;
    }
  }, [currentIndex]);

  const handleImageClick = async (link, index) => {
    if (index === 2) {
      if (user) return;
      try {
        props.setLoadingForYT(true);
        const resultOfLoginAndYoutube = await loginAndYoutube();
        const isNotNeededToAddPoint = resultOfLoginAndYoutube[4];
        if (isNotNeededToAddPoint === false) {
          const newPoint = resultOfLoginAndYoutube[6];
          props.setCurrentPoint(newPoint);
          props.setLoadingForYT(false);
        } else {
          props.setLoadingForYT(false);
        }
      } catch (error) {
        alert(`${ERROR_MSG_PREFIX} ${error.message}`);
        props.setLoadingForYT(false);
        return;
      }
    } else if (link) {
      window.open(link, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className="carousel__wrapper">
      <div className="carousel">
        <div className="carousel__viewport" ref={viewportRef}>
          {images.map((image, index) => (
            <div key={index} className="carousel__slide">
              <img
                src={image.url}
                alt={`Slide ${index + 1}`}
                onClick={() => handleImageClick(image.link, index)}
                style={{ cursor: image.link ? "pointer" : "default" }}
              />
            </div>
          ))}
        </div>
        <button
          className="carousel__button carousel__button--prev"
          onClick={prevSlide}
        >
          &#10094;
        </button>
        <button
          className="carousel__button carousel__button--next"
          onClick={nextSlide}
        >
          &#10095;
        </button>
      </div>

      <div className="carousel__indicators">
        {images.map((_, index) => (
          <button
            key={index}
            className={`carousel__indicator ${
              currentIndex === index ? "active" : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default MyCarousel;
