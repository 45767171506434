import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  displayPostalCode,
  getSelectedAddressIndex,
  setAllIsSelectedFalse,
} from "../utils/utils";
import { addressesState } from "../stores/addressesState";
import { RadioGroup } from "@headlessui/react";
import { BiRadioCircleMarked, BiRadioCircle } from "react-icons/bi";
import { isOpenDeleteAddressModalState } from "../stores/isOpenDeleteAddressModalState";
import { deleteAddressIndexState } from "../stores/deleteAddressIndex";
import { selectAddress } from "../actions/backend";
import { ERROR_MSG_PREFIX } from "../const/error-message";

export function AddressRadioBox(props) {
  const [addresses, setAddresses] = useAtom(addressesState);
  const [selectedIndex, setSelectedIndex] = useState(
    getSelectedAddressIndex(addresses)
  );

  const handleChange = async (value) => {
    if (addresses.length === 0) return;
    try {
      await selectAddress(value);
    } catch (error) {
      console.error(error);
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
    const newAddress = setAllIsSelectedFalse([...addresses]);
    newAddress[value].isSelected = true;
    setAddresses(newAddress);
  };

  useEffect(() => {
    for (let i = 0; i < addresses.length; i++) {
      if (addresses[i].isSelected) {
        setSelectedIndex(i);
        break;
      }
    }
  }, [addresses]);

  return (
    <RadioGroup value={selectedIndex} onChange={handleChange} {...props}>
      {addresses.length === 0 ? (
        <></>
      ) : (
        <>
          {addresses.map((address, i) => {
            return (
              <div
                key={i}
                className={clsx(
                  "flex justify-between items-center",
                  "border-b-[1px] px-2 py-4"
                )}
              >
                <RadioGroup.Option
                  key={i}
                  className={clsx("cursor-pointer")}
                  value={i}
                >
                  {({ checked }) => (
                    <>
                      <div className={clsx("flex items-center")}>
                        {checked ? (
                          <BiRadioCircleMarked
                            className={clsx("w-6 h-6", "text-blue-500")}
                          />
                        ) : (
                          <BiRadioCircle
                            className={clsx("w-6 h-6", "text-gray-300")}
                          />
                        )}
                        <div
                          className={clsx(
                            "text-start max-w-[80%] flex-wrap",
                            "ml-4"
                          )}
                        >
                          <div>{`${address.lastName} ${address.firstName}`}</div>
                          <div className={clsx("text-xs text-gray-500")}>
                            <div>{displayPostalCode(address.postalCode)}</div>
                            <div>{`${address.prefecture}${address.addressDesc}`}</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
                <div className={clsx("flex")}>
                  <EditButton index={i} />
                  <DeleteButton index={i} />
                </div>
              </div>
            );
          })}
        </>
      )}
    </RadioGroup>
  );
}

const EditButton = (props) => {
  const { className, index, ...otherProps } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem("navigateFromAddOrEdit", JSON.stringify(true));
    navigate(`/my-page/edit-address/${index}`);
  };

  return (
    <button
      className={clsx(
        className,
        "rounded px-3 py-2",
        "bg-gray-200",
        "font-bold text-sm",
        "hover:opacity-70"
      )}
      onClick={handleClick}
      {...otherProps}
    >
      変更
    </button>
  );
};

const DeleteButton = (props) => {
  const { className, index, ...otherProps } = props;
  const addresses = useAtomValue(addressesState);
  const setIsOpen = useSetAtom(isOpenDeleteAddressModalState);
  const setDeleteAddressIndex = useSetAtom(deleteAddressIndexState);

  const handleClick = () => {
    if (addresses.length <= 1) {
      alert("住所は1つ以上必要です");
      return;
    }
    setDeleteAddressIndex(index);
    setIsOpen(true);
  };

  return (
    <button
      className={clsx(
        className,
        "rounded px-3 py-2",
        "font-bold text-sm text-red-500",
        "hover:opacity-70"
      )}
      onClick={handleClick}
      {...otherProps}
    >
      削除
    </button>
  );
};
