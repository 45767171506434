import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import JGSLoaing from "../../components/JGSLoaing";

export function StripePaymentForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (result.error) {
      console.error("エラーが発生しました:", result.error);
    } else {
      // ここでは決済完了の処理を行わず、結果のみをログに出力
      props.setPaymentStatus(true);
      props.setOpenModal(true);
    }
    setLoading(false);
  };

  return (
    <form style={{ marginTop: "20px" }} onSubmit={handleSubmit}>
      {loading && <JGSLoaing />}
      <PaymentElement />
      <button
        type="submit"
        className="w-full sm:w-56 max-w-[14rem] px-4 py-2 mt-8 bg-black hover:bg-jgs-main-hovered text-yellow-300 font-bold rounded-full"
      >
        購入する
      </button>
    </form>
  );
}
