import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

export function ShipmentComplete(props) {
  const navigate = useNavigate();

  return (
    <div
      className={clsx(
        "w-full h-[182px] md:h-[198px] flex items-center justify-center flex-col"
      )}
    >
      <div className="w-full flex flex-col items-center gap-4">
        <div className="flex-col items-center text-[24px] leading-tight font-bold">
          <h1>カードの発送手続きが</h1>
          <h1>完了しました</h1>
        </div>
        <p className={clsx("text-xs m-0 font-bold")}>
          お届けまで少々お待ちください。
        </p>
        <div className="flex items-center gap-4">
          <button
            className="flex-auto bg-blue-800 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded-full"
            onClick={() => {
              props.setFooterNum(0);
              if (props.setOpenResultModal) props.setOpenResultModal(false);
              if (props.setNeedToUpdateCardList)
                props.setNeedToUpdateCardList(true);
            }}
          >
            戻る
          </button>
          <button
            className="flex-auto bg-blue-800 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded-full"
            onClick={() => {
              navigate("/my-page/address");
            }}
          >
            住所登録
          </button>
        </div>
      </div>
    </div>
  );
}
