import React from "react";
import { LoginButton } from "./LoginButton.tsx";
import Mask from "../Mask";
import ModalCard from "../ModalCard";

export function AuthModal({ closeModal }) {
  return (
    <Mask closeModal={closeModal}>
      <ModalCard closeModal={closeModal}>
        <p className="mb-4">ガチャるにはログインが必要です。</p>
        <LoginButton />
      </ModalCard>
    </Mask>
  );
}
