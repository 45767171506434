import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { BiUserCircle, BiPlusCircle } from "react-icons/bi";
import { getPoint } from "../actions/point";
import logoJGS from "../assets/JGS_logo1.png";
import logoYT from "../assets/yt_logo_rgb_light.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../package/firebase";
import { useAtom } from "jotai";
import { isOpenMyPageState } from "../stores/isOpenMyPageState";
import { MyPageSideBar } from "./my-page-side-bar";
import { Spinner } from "./spinner";
import { login, loginAndYoutube } from "../actions/auth";
import { ERROR_MSG_PREFIX } from "../const/error-message";

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx("cursor-pointer", "text-2xl")}
      onClick={() => {
        navigate("/");
      }}
    >
      <img src={logoJGS} className={clsx("w-10 h-10")} alt="logo" />
    </div>
  );
};

export const Point = ({ point }) => {
  const navigate = useNavigate();
  return (
    <button
      className={clsx(
        "mr-2",
        "text-white font-bold bg-jgs-point-amount",
        "rounded-full",
        "pl-4 pr-1",
        "flex items-center",
        "h-[36px] md:h-[40px]"
      )}
      onClick={() => {
        navigate("/point-purchase-list");
      }}
    >
      {point} pt
      <span className={clsx("ml-2", "text-black font-bold")}>
        <BiPlusCircle
          className={clsx("w-[24px] h-[24px]", "text-jgs-btn-point-add")}
        />
      </span>
    </button>
  );
};

const UserIcon = () => {
  const [isOpenMyPage, setIsOpenMyPage] = useAtom(isOpenMyPageState);
  const toggleMenu = () => {
    setIsOpenMyPage(!isOpenMyPage);
  };

  return (
    <button onClick={toggleMenu} className="flex items-center">
      <BiUserCircle
        className={clsx(
          "w-[24px] md:w-[36px] h-[24px] md:h-[36px]",
          "text-white rounded-full"
        )}
      />
    </button>
  );
};

const LoginButton = () => {
  return (
    <button
      className={clsx(
        "min-w-[150px]",
        "px-2 py-1",
        "bg-transparent",
        "rounded-md",
        "border border-white",
        "text-white font-bold",
        "hover:text-gray-100 hover:border-gray-100"
      )}
    >
      会員登録/ログイン
    </button>
  );
};

export const TopPageHeader = ({
  gachaList,
  currentPoint,
  setCurrentPoint,
  setLoadingForYT,
}) => {
  const [user, loading] = useAuthState(fireauth);
  // eslint-disable-next-line no-unused-vars
  const [tagList, setTagList] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleLoginButtonClick = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    const fetchPoint = async () => {
      if (!user) return;
      try {
        const tmp = await getPoint(user.uid);
        if (tmp >= 0) {
          const newPoint = localStorage.getItem("newPoint");
          if (newPoint && newPoint > tmp) {
            setCurrentPoint(newPoint);
            return;
          }
          setCurrentPoint(tmp);
        }
      } catch (error) {
        console.error("getPoint error occurred: ", error);
      }
    };
    fetchPoint();
  }, []);

  useEffect(() => {
    const fetchPoint = async () => {
      if (!user) return;
      try {
        const tmp = await getPoint(user.uid);
        if (tmp >= 0) {
          const newPoint = localStorage.getItem("newPoint");
          if (newPoint && newPoint > tmp) {
            setCurrentPoint(newPoint);
            return;
          }
          setCurrentPoint(tmp);
        }
      } catch (error) {
        console.error("getPoint error occurred: ", error);
      }
    };
    fetchPoint();

    const uniqueTags = new Set();
    uniqueTags.add("all");
    gachaList.forEach((gachaItem) => {
      gachaItem.tags.forEach((tag) => {
        uniqueTags.add(tag);
      });
    });
    const uniqueTagsArray = Array.from(uniqueTags);
    setTagList(uniqueTagsArray);
  }, [user, gachaList]);

  const loginOrRegister = async () => {
    try {
      await login();
      setShowTooltip(!showTooltip);
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      setShowTooltip(!showTooltip);
      return;
    }
  };

  const loginWithYT = async () => {
    try {
      setLoadingForYT(true);
      const resultOfLoginAndYoutube = await loginAndYoutube();
      const isNotNeededToAddPoint = resultOfLoginAndYoutube[4];
      if (isNotNeededToAddPoint === false) {
        const newPoint = resultOfLoginAndYoutube[6];
        setCurrentPoint(newPoint);
        setLoadingForYT(false);
      } else {
        setLoadingForYT(false);
      }
      setShowTooltip(!showTooltip);
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      setShowTooltip(!showTooltip);
      setLoadingForYT(false);
      return;
    }
  };

  return (
    <header
      className={clsx(
        "h-[64px] md:h-[96px]",
        "flex justify-center items-center",
        "w-full",
        "p-2",
        "bg-jgs-main",
        "fixed z-30",
        "shadow-detail-header"
      )}
    >
      <div className={clsx("flex flex-col", "w-full max-w-[768px]")}>
        <div className={clsx("flex justify-between items-center")}>
          <Logo />
          <div className={clsx("flex items-center")}>
            {loading ? (
              <Spinner className={clsx("w-4 h-4 border-[2px]")} />
            ) : (
              <>
                {user && <Point point={currentPoint} />}
                <div className={clsx("relative")}>
                  {user ? (
                    <UserIcon />
                  ) : (
                    <>
                      <div onClick={handleLoginButtonClick}>
                        <LoginButton />
                      </div>
                      {showTooltip && (
                        <div
                          className={clsx(
                            "absolute",
                            "bottom-0",
                            "translate-y-full",
                            "right-0",
                            "bg-white/90",
                            "translate-y-[calc(100%+8px)]",
                            "flex",
                            "flex-col",
                            "gap-2",
                            "p-2",
                            "rounded-lg"
                          )}
                        >
                          <button
                            className={clsx(
                              "min-w-[150px]",
                              "px-2 py-1",
                              "bg-transparent",
                              "rounded-md",
                              "border border-black",
                              "text-black font-bold",
                              "hover:bg-gray-100",
                              "text-sm",
                              "h-[37.586px]"
                            )}
                            onClick={loginOrRegister}
                          >
                            会員登録/ログイン
                          </button>
                          <button
                            className={clsx(
                              "min-w-[150px]",
                              "px-2 py-1",
                              "bg-transparent",
                              "rounded-md",
                              "border border-black",
                              "text-black font-bold",
                              "hover:bg-gray-100"
                            )}
                            onClick={loginWithYT}
                          >
                            <img
                              src={logoYT}
                              className={clsx("w-full")}
                              alt="logo"
                            />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <MyPageSideBar />
      </div>
    </header>
  );
};
