import React, { useEffect, useState } from "react";
import "./styles/App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { PointList } from "./pages/point/index";
import { MyPayment } from "./pages/stripe/payment";
import { RouletteIndex } from "./pages/roulette";
import { RouletteDetail } from "./pages/roulette/detail";
import { PrivacyPolicy } from "./pages/roulette/pp";
import { Profile } from "./pages/profile/profile";
import { TopPageHeader } from "./components/header";
import { OurSimplifiedColoredHeader } from "./components/header-simple-color";
import { EditAddress } from "./pages/edit-address/edit-address";
import { AddAddress } from "./pages/add-address/add-address";
import { Address } from "./pages/address/address";
import { History } from "./pages/history/History";
import { useSetAtom } from "jotai";
import { addressesState } from "./stores/addressesState";
import { getAddresses } from "./actions/user";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "./package/firebase";
import {
  createUser,
  createUserWithYT,
  getCardList,
  getPackages,
} from "./actions/backend";
import {
  ALREADY_EXISTED_USER_MSG,
  ERROR_MSG_PREFIX,
} from "./const/error-message";
import Admin from "./pages/admin/admin";
import Preview from "./pages/admin/preview";
import JGSLoaingForYT from "./components/JGSLoaingForYT";

const App = () => {
  const [gachaList, setGachaList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [filteredGachaList, setFilteredGachaList] = useState([]);
  const [gachaIds, setGachaIds] = useState([]);
  const [hitList, setHitList] = useState([]);
  const [selectedTag, setSelectedTag] = useState("all");
  const setAddresses = useSetAtom(addressesState);
  const [user] = useAuthState(fireauth);
  const location = useLocation();
  const isRootRoute = location.pathname === "/";
  const isPointPurchaseListRoute = location.pathname === "/point-purchase-list";
  const [needToUpdateGachaList, setNeedToUpdateGachaList] = useState(false);
  const [loadingForYT, setLoadingForYT] = useState(false);
  const [currentPoint, setCurrentPoint] = useState(0);

  useEffect(() => {
    getPackages()
      .then(({ gachaList }) => {
        const sortedGachaList = gachaList
          .slice()
          .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds);
        setGachaList(sortedGachaList);
        const gachaIdsList = sortedGachaList.map((gacha) => gacha.id);
        setGachaIds(gachaIdsList);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    let filteredGachaList = [];

    if (selectedTag === "all") {
      filteredGachaList = [...gachaList]; // selectedTagが'all'の場合はフィルターなし
    } else {
      filteredGachaList = gachaList.filter((gachaItem) => {
        return gachaItem.tags.includes(selectedTag); // selectedTagがtags配列に含まれていればフィルタリング
      });
      setFilteredGachaList(filteredGachaList);
    }
  }, [selectedTag]);

  useEffect(() => {
    // 各fetchリクエストをPromiseとして管理
    const fetchPromises = gachaIds.map(async (gachaId) => {
      const res = await getCardList(gachaId);
      return res["cardList"];
    });
    // すべてのfetchリクエストが完了するのを待つ
    Promise.all(fetchPromises).then((dataArr) => {
      const hitListArray = {};
      for (let i = 0; i < gachaIds.length; i++) {
        hitListArray[gachaIds[i]] = dataArr[i];
      }
      setHitList(hitListArray);
    });
  }, [gachaIds]);

  useEffect(() => {
    const uniqueTags = new Set();
    uniqueTags.add("all");
    gachaList.forEach((gachaItem) => {
      gachaItem.tags.forEach((tag) => {
        uniqueTags.add(tag);
      });
    });
    const uniqueTagsArray = Array.from(uniqueTags);
    setTagList(uniqueTagsArray);
  }, [gachaList]);

  useEffect(() => {
    if (needToUpdateGachaList) {
      getPackages()
        .then(({ gachaList }) => {
          setGachaList(gachaList);
          let gachaIdsList = [];
          gachaList.map((gacha) => {
            gachaIdsList.push(gacha.id);
          });
          setGachaIds(gachaIdsList);
        })
        .catch((err) => {
          console.error(err);
        });
      setNeedToUpdateGachaList(false);
    }
  }, [needToUpdateGachaList]);

  const init = async (user) => {
    if (!user) return;
    try {
      const storedSubscriberCount = localStorage.getItem("count");
      if (!storedSubscriberCount) {
        await createUser();
      } else {
        await createUserWithYT(storedSubscriberCount);
        localStorage.removeItem("count");
      }
    } catch (error) {
      if (error.message !== ALREADY_EXISTED_USER_MSG) {
        console.error(error);
        alert(`${ERROR_MSG_PREFIX} ${error.message}`);
        return;
      }
    }
    try {
      const addresses = await getAddresses();
      if (!addresses) return;
      setAddresses(addresses);
    } catch (error) {
      console.error(error);
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
  };

  useEffect(() => {
    init(user);
  }, [user]);

  return (
    <div className="App">
      {loadingForYT && <JGSLoaingForYT />}
      {isRootRoute && (
        <TopPageHeader
          gachaList={gachaList}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          currentPoint={currentPoint}
          setCurrentPoint={setCurrentPoint}
          setLoadingForYT={setLoadingForYT}
        />
      )}
      {isRootRoute && <div className="z-30 bg-jgs-main h-[64px] md:h-[96px]" />}
      {/* {isRouletteRoute && <GeneralHeader />} */}
      {isPointPurchaseListRoute && <OurSimplifiedColoredHeader />}
      <Routes>
        <Route
          path="/"
          element={
            <RouletteIndex
              gachaList={selectedTag === "all" ? gachaList : filteredGachaList}
              hitList={hitList}
              selectedTag={selectedTag}
              setSelectedTag={setSelectedTag}
              tagList={tagList}
              currentPoint={currentPoint}
              setCurrentPoint={setCurrentPoint}
              setLoadingForYT={setLoadingForYT}
            />
          }
        />
        <Route
          path="/roulette/:rouletteId"
          element={
            <RouletteDetail
              needToUpdateGachaList={needToUpdateGachaList}
              setNeedToUpdateGachaList={setNeedToUpdateGachaList}
            ></RouletteDetail>
          }
        />
        <Route path="/point-purchase-list" element={<PointList />} />
        <Route path="/payment/:price" element={<MyPayment />} />
        <Route path="/my-page/profile" element={<Profile />} />
        <Route path="/my-page/edit-address/:index" element={<EditAddress />} />
        <Route path="/my-page/add-address" element={<AddAddress />} />
        <Route path="/my-page/address" element={<Address />} />
        <Route path="/my-page/history" element={<History />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/preview" element={<Preview />} />
        <Route path="/google-privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/contact" element={<ContactForm />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default App;
