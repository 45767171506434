import React from "react";
import { useNavigate } from "react-router-dom";

export function Modal({ setOpenModal }) {
  const navigate = useNavigate();
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={() => setOpenModal(false)}
    >
      <div
        className="bg-white rounded-xl max-w-xl w-4/5"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-6">
          <h2 className="text-xl font-bold">ポイントが足りません</h2>
        </div>
        <div className="px-6 py-4">
          <p>ガチャを回すためにはポイントが必要です。</p>
          <p>ポイントはポイント購入ページから</p>
          <p>チャージできます。</p>
        </div>
        <div className="px-6 py-4 flex flex-col items-center gap-4">
          <button
            className="w-56 bg-black hover:bg-jgs-main-hovered text-yellow-300 font-bold py-2 px-4 rounded-full"
            onClick={() => {
              navigate("/point-purchase-list");
            }}
          >
            ポイントを購入する
          </button>
          <button
            className="w-56 bg-gray-400 hover:bg-jgs-main-hovered text-black font-bold py-2 px-4 rounded-full"
            onClick={() => setOpenModal(false)}
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  );
}
