import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../../package/firebase";
import { useSetAtom } from "jotai";
import { isOpenMyPageState } from "../../stores/isOpenMyPageState";
import { pointOfCurrentUserState } from "../../stores/pointOfCurrentUserState";
import {
  exchange,
  getPackages,
  getCardList,
  roulette,
  requestShipment,
} from "../../actions/backend";
import { getPoint } from "../../actions/point";
import { GeneralHeader } from "../../components/header-simple";
import JGSLoaing from "../../components/JGSLoaing";
import { findHighestRank } from "../../utils/utils";
import { ERROR_MSG_PREFIX } from "../../const/error-message";

import { ProgressBar } from "../../components/roulette/ProgressBar.tsx";
import { Modal } from "../../components/roulette/Modal.tsx";
import { ResultFooter } from "../../components/roulette/ResultFooter.tsx";
import { AuthModal } from "../../components/roulette/AuthModal.tsx";

import movRankOneSuperLight from "../../assets/rank1-super-light.mp4";
import movRankTwoSuperLight from "../../assets/rank2-super-light.mp4";
import movRankThreeSuperLight from "../../assets/rank3-super-light.mp4";
import movRankFourSuperLight from "../../assets/rank4-super-light.mp4";
import rankOne from "../../assets/rank-one.png";
import rankTwo from "../../assets/rank-two.png";
import rankThree from "../../assets/rank-three.png";
import rankFour from "../../assets/rank-four.png";
import btnGacha from "../../assets/btn-gacha.png";
import btnGacha10 from "../../assets/btn-gacha10.png";

export function RouletteDetail({
  needToUpdateGachaList,
  setNeedToUpdateGachaList,
}) {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [user, loading, error] = useAuthState(fireauth);
  const isOpenMyPage = useSetAtom(isOpenMyPageState);
  const pointOfCurrentUser = useSetAtom(pointOfCurrentUserState);
  const id = useParams().rouletteId;
  const [packageData, setPackageData] = useState({});
  const [numberOfInventory, setNumberOfInventory] = useState(0);
  const [resultList, setResultList] = useState([]);
  const [cardList, setCardList] = useState([]);
  const [groupByRank, setGroupByRank] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [exchangedList, setExchangedList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [shippedList, setShippedList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [exchangedAmount, setExchangedAmount] = useState(0);
  const [footerNum, setFooterNum] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalValOfselectedItems, setTotalValOfselectedItems] = useState([]);
  const [pointUpdated, setPointUpdated] = useState(false);
  const [movie, setMovie] = useState(null);
  const videoRef = useRef(null);
  const [onLoad, setOnLoad] = useState(false);
  const [isModalOpen, setIsOpenModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState([]);
  const [cardLength, setCardLength] = useState(0);
  const [loadedItems, setLoadedItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isGachaDisabled, setIsGachaDisabled] = useState(false);
  const [isGacha10Disabled, setIsGacha10Disabled] = useState(false);

  useEffect(() => {
    isOpenMyPage(false);
    if (localStorage.getItem("rootPage")) {
      setResultList(JSON.parse(localStorage.getItem("resultList")));
      setOpenResultModal(true);
      setFooterNum(0);
      localStorage.removeItem("resultList");
      localStorage.removeItem("rootPage");
      localStorage.removeItem("detailId");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const { gachaList } = await getPackages();
        const targetPackage = gachaList.find((gacha) => gacha.id === id);
        setPackageData(targetPackage);
        const { cardList } = await getCardList(id);
        setCardList(cardList);
        const inventory =
          cardList &&
          cardList.filter((card) => !card.holderId || card.holderId === "")
            .length;
        setNumberOfInventory(inventory);
        const groupByRank = cardList.reduce((acc, item) => {
          if (!acc[item.rank]) {
            acc[item.rank] = [];
          }
          acc[item.rank].push(item);
          return acc;
        }, {});
        const uniqueGroupByRank = Object.keys(groupByRank).reduce(
          (acc, rank) => {
            const uniqueItems = groupByRank[rank].filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.name === item.name)
            );
            acc[rank] = uniqueItems;
            setCardLength((prev) => prev + uniqueItems.length);
            return acc;
          },
          {}
        );
        let totalUniqueItems = 0;
        Object.values(uniqueGroupByRank).forEach((items) => {
          totalUniqueItems += items.length;
        });
        setCardLength(totalUniqueItems);
        setGroupByRank(uniqueGroupByRank);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [id, needToUpdateGachaList]);

  useEffect(() => {
    if (closeModal) {
      setIsOpenModal(false);
    }
  }, [user]);

  useEffect(() => {
    const sum = exchangedList.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.value;
    }, 0);
    setExchangedAmount(sum);
  }, [exchangedList]);

  useEffect(() => {}, [selectedItems]);

  useEffect(() => {
    const totalValue = selectedItems.reduce((acc, currentItem) => {
      return acc + Number(currentItem.value);
    }, 0);
    setTotalValOfselectedItems(totalValue);
  }, [selectedItems]);

  useEffect(() => {
    loadedItems === cardLength && setIsLoading(false);
  }, [loadedItems]);

  const fetchCardList = async () => {
    try {
      const { cardList } = await getCardList(id);
      setCardList(cardList);
    } catch (err) {
      console.error(err);
    }
  };

  const allowMedia = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
          const handleVideoEnd = () => {
            videoElement.removeEventListener("ended", handleVideoEnd);
            resolve();
          };
          videoElement.addEventListener("ended", handleVideoEnd);
          const playPromise = videoElement.play();
          if (playPromise !== undefined) {
            playPromise
              .then(() => {})
              .catch((error) => {
                console.error("error: ", error);
                reject(error);
              });
          } else {
            console.error("playPromise id falsy.");
            reject(error);
          }
        } else {
          console.error("Video element is not available.");
          reject(error);
        }
      }, 100);
    });
  };

  const gacha = async (times) => {
    if (isGachaDisabled || isGacha10Disabled) return;

    setIsGachaDisabled(true);
    setIsGacha10Disabled(true);

    if (!user) {
      setIsOpenModal(true);
      setIsGachaDisabled(false);
      setIsGacha10Disabled(false);
      return;
    }
    let currentPoint = 0;
    try {
      currentPoint = await getPoint(user.uid);
      if (currentPoint) pointOfCurrentUser(currentPoint);
    } catch (error) {
      console.error("getPoint error occurred: ", error);
      setIsGachaDisabled(false);
      setIsGacha10Disabled(false);
    }
    if (currentPoint < packageData.price * times) {
      alert("ガチャを回せるポイントが足りません。ポイントを購入してください。");
      setIsGachaDisabled(false);
      setIsGacha10Disabled(false);
      return;
    }
    if (numberOfInventory < times) {
      alert("在庫が足りません。");
      setIsGachaDisabled(false);
      setIsGacha10Disabled(false);
      return;
    }
    try {
      const { obtainedCardList } = await roulette(id, times);
      selectMovie(obtainedCardList);
      setResultList(obtainedCardList);
      setOnLoad(true);
      await allowMedia();
      setOnLoad(false);
      setFooterNum(0);
      setOpenResultModal(true);
      setNeedToUpdateGachaList(true);
      setPointUpdated(true);
      setIsGachaDisabled(false);
      setIsGacha10Disabled(false);
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      setIsGachaDisabled(false);
      setIsGacha10Disabled(false);
    }
  };

  const selectMovie = (obtainedCardList) => {
    const rank = findHighestRank(obtainedCardList);
    switch (rank) {
      case "1":
        setMovie(movRankOneSuperLight);
        break;
      case "2":
        setMovie(movRankTwoSuperLight);
        break;
      case "3":
        setMovie(movRankThreeSuperLight);
        break;
      case "4":
        setMovie(movRankFourSuperLight);
        break;
      default:
        setMovie(movRankFourSuperLight);
        break;
    }
  };

  async function exchangeAction() {
    const targetItems = selectedItems;
    const contentIds = targetItems.map((content) => content.id);
    if (contentIds && contentIds.length === 0) {
      alert("還元対象の商品がありません。");
      return;
    }
    try {
      const { targetCardList } = await exchange(contentIds);
      setExchangedList(targetCardList);
      removeSelectedCardFromResult();
      try {
        const tmp = await getPoint(user.uid);
        if (tmp) pointOfCurrentUser(tmp);
      } catch (error) {
        console.error("getPoint error occurred: ", error);
      }
      await fetchCardList();
      setFooterNum(2);
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
  }

  async function shipmentAction() {
    const targetItems = selectedItems;
    const contentIds = targetItems.map((content) => content.id);
    if (contentIds && contentIds.length === 0) {
      alert("発送対象の商品がありません。");
      return;
    }
    try {
      const { targetCardList } = await requestShipment(contentIds);
      setShippedList(targetCardList);
      removeSelectedCardFromResult();
      await fetchCardList();
      setFooterNum(4);
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
  }

  const removeSelectedCardFromResult = () => {
    const filteredResultList = resultList.filter((item) => {
      return !selectedItems.some((selectedItem) => {
        return item.id === selectedItem.id;
      });
    });
    setResultList(filteredResultList);
    setSelectedItems([]);
  };

  const selectAllCard = () => {
    setSelectedItems(resultList);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <div className="h-screen w-full">
      {isModalOpen && <AuthModal closeModal={closeModal} />}
      {isLoading && <JGSLoaing />}
      {openModal && <Modal setOpenModal={setOpenModal} />}
      {onLoad && (
        <video
          className={clsx("h-screen w-screen fixed bg-white z-40")}
          ref={videoRef}
          width="100%"
          autoPlay
          playsInline
        >
          <source src={movie} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      )}
      {!onLoad && (
        <GeneralHeader
          pointUpdated={pointUpdated}
          setPointUpdated={setPointUpdated}
        />
      )}
      {openResultModal ? (
        <ResultFooter
          resultList={resultList}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          totalValOfselectedItems={totalValOfselectedItems}
          footerNum={footerNum}
          setFooterNum={setFooterNum}
          exchangeAction={exchangeAction}
          shipmentAction={shipmentAction}
          setOpenResultModal={setOpenResultModal}
          id={id}
          selectAllCard={selectAllCard}
        />
      ) : (
        <div className={clsx("flex flex-col", "max-w-[800px] mx-auto")}>
          <img
            src={packageData.img}
            className="mt-[80px] md:mt-[112px] max-w-[800px] w-screen fixed top-0 left-1/2 transform -translate-x-1/2 z-10 md:object-contain object-contain"
            alt="eyecatch image"
          />
          <div
            className={clsx(
              "max-w-[540px] w-full h-[126px] rounded-t-[16px] mx-auto fixed bottom-0 left-1/2 transform -translate-x-1/2 bg-jgs-main z-30"
            )}
          >
            {!onLoad && (
              <div
                className={clsx(
                  "w-full max-w-[382px] flex mx-auto p-[16px] flex-col items-center text-white"
                )}
              >
                <div
                  className={clsx(
                    "mb-[10px] flex items-center justify-between w-full"
                  )}
                >
                  <p className="text-[24px] font-[800]">
                    {packageData.price}
                    <span className="text-[14px] font-[600]">Pt/1回</span>
                  </p>
                  <ProgressBar
                    numberOfInventory={numberOfInventory}
                    cardList={cardList}
                  />
                </div>
                <div className="w-full flex items-center">
                  <button
                    className="flex-1 mr-[8px]"
                    disabled={isGachaDisabled}
                    onClick={async (e) => {
                      e.preventDefault();
                      await gacha(1);
                    }}
                  >
                    <img src={btnGacha} alt="ガチャる" />
                  </button>
                  <button
                    className="flex-1"
                    disabled={isGacha10Disabled}
                    onClick={async (e) => {
                      e.preventDefault();
                      await gacha(10);
                    }}
                  >
                    <img src={btnGacha10} alt="10連ガチャる" />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div
            style={{ marginTop: "75vh" }}
            className={clsx(
              "min-h-screen p-4 pb-56 gap-4 flex flex-col items-center bg-white z-20"
            )}
          >
            <img src={rankOne} className={clsx("w-1/2")} alt="rank one" />
            <div
              className={clsx(
                "w-full my-2 overflow-x-auto whitespace-nowrap",
                {
                  "grid grid-rows-2 grid-flow-col gap-4":
                    groupByRank && groupByRank[1] && groupByRank[1].length >= 2,
                },
                {
                  "flex gap-4": !(
                    groupByRank &&
                    groupByRank[1] &&
                    groupByRank[1].length >= 2
                  ),
                }
              )}
            >
              {groupByRank &&
                groupByRank[1] &&
                groupByRank[1].map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="w-24 font-bold rounded-xl flex-none"
                    >
                      {isLoaded?.find(
                        (i) => i.id === item.id && i.state === true
                      ) && (
                        <img
                          src={item.img}
                          alt="rank1"
                          onLoad={() => {
                            setIsLoaded((prevState) => [
                              ...prevState,
                              { id: item.id, state: true },
                            ]);
                          }}
                        />
                      )}
                      {!isLoaded?.find(
                        (i) => i.id === item.id && i.state === true
                      ) && (
                        <div
                          role="status"
                          className="flex items-center justify-center bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
                        >
                          <svg
                            className="text-gray-200 dark:text-gray-600"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 16 20"
                          >
                            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
                          </svg>
                          <span className="sr-only">Loading...</span>
                          <img
                            src={item.img}
                            alt="rank1"
                            onLoad={() => {
                              setIsLoaded((prevState) => [
                                ...prevState,
                                { id: item.id, state: true },
                              ]);
                              setLoadedItems((prev) => prev + 1);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>

            <img src={rankTwo} className={clsx("w-1/2")} alt="rank two" />
            <div
              className={clsx(
                "w-full my-2 overflow-x-auto whitespace-nowrap",
                {
                  "grid grid-rows-2 grid-flow-col gap-4":
                    groupByRank && groupByRank[2] && groupByRank[2].length >= 2,
                },
                {
                  "flex gap-4": !(
                    groupByRank &&
                    groupByRank[2] &&
                    groupByRank[2].length >= 2
                  ),
                }
              )}
            >
              {groupByRank &&
                groupByRank[2] &&
                groupByRank[2].map((item, index) => (
                  <div
                    key={index}
                    className="w-24 font-bold rounded-xl flex-none"
                  >
                    {isLoaded?.find(
                      (i) => i.id === item.id && i.state === true
                    ) && (
                      <img
                        src={item.img}
                        alt="rank1"
                        onLoad={() => {
                          setIsLoaded((prevState) => [
                            ...prevState,
                            { id: item.id, state: true },
                          ]);
                        }}
                      />
                    )}
                    {!isLoaded?.find(
                      (i) => i.id === item.id && i.state === true
                    ) && (
                      <div
                        role="status"
                        className="flex items-center justify-center bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
                      >
                        <svg
                          className="text-gray-200 dark:text-gray-600"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 16 20"
                        >
                          <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                          <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                        <img
                          src={item.img}
                          alt="rank2"
                          onLoad={() => {
                            setIsLoaded((prevState) => [
                              ...prevState,
                              { id: item.id, state: true },
                            ]);
                            setLoadedItems((prev) => prev + 1);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>

            <img src={rankThree} className={clsx("w-1/2")} alt="rank three" />
            <div
              className={clsx(
                "w-full my-2 overflow-x-auto whitespace-nowrap",
                {
                  "grid grid-rows-2 grid-flow-col gap-4":
                    groupByRank && groupByRank[3] && groupByRank[3].length >= 2,
                },
                {
                  "flex gap-4": !(
                    groupByRank &&
                    groupByRank[3] &&
                    groupByRank[3].length >= 2
                  ),
                }
              )}
            >
              {groupByRank &&
                groupByRank[3] &&
                groupByRank[3].map((item, index) => (
                  <div
                    key={index}
                    className="w-24 font-bold rounded-xl flex-none"
                  >
                    {isLoaded?.find(
                      (i) => i.id === item.id && i.state === true
                    ) && (
                      <img
                        src={item.img}
                        alt="rank1"
                        onLoad={() => {
                          setIsLoaded((prevState) => [
                            ...prevState,
                            { id: item.id, state: true },
                          ]);
                        }}
                      />
                    )}
                    {!isLoaded?.find(
                      (i) => i.id === item.id && i.state === true
                    ) && (
                      <div
                        role="status"
                        className="flex items-center justify-center bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
                      >
                        <svg
                          className="text-gray-200 dark:text-gray-600"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 16 20"
                        >
                          <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                          <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                        <img
                          src={item.img}
                          alt="rank3"
                          onLoad={() => {
                            setIsLoaded((prevState) => [
                              ...prevState,
                              { id: item.id, state: true },
                            ]);
                            setLoadedItems((prev) => prev + 1);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>

            <img src={rankFour} className={clsx("w-1/2")} alt="rank four" />
            <div
              className={clsx(
                "w-full my-2 overflow-x-auto whitespace-nowrap",
                {
                  "grid grid-rows-2 grid-flow-col gap-4":
                    groupByRank && groupByRank[4] && groupByRank[4].length >= 2,
                },
                {
                  "flex gap-4": !(
                    groupByRank &&
                    groupByRank[4] &&
                    groupByRank[4].length >= 2
                  ),
                }
              )}
            >
              {groupByRank &&
                groupByRank[4] &&
                groupByRank[4].map((item, index) => (
                  <div
                    key={index}
                    className="w-24 font-bold rounded-xl flex-none"
                  >
                    {isLoaded?.find(
                      (i) => i.id === item.id && i.state === true
                    ) && (
                      <img
                        src={item.img}
                        alt="rank1"
                        onLoad={() => {
                          setIsLoaded((prevState) => [
                            ...prevState,
                            { id: item.id, state: true },
                          ]);
                        }}
                      />
                    )}
                    {!isLoaded?.find(
                      (i) => i.id === item.id && i.state === true
                    ) && (
                      <div
                        role="status"
                        className="flex items-center justify-center bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
                      >
                        <svg
                          className="text-gray-200 dark:text-gray-600"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 16 20"
                        >
                          <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                          <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                        <img
                          src={item.img}
                          alt="rank4"
                          onLoad={() => {
                            setIsLoaded((prevState) => [
                              ...prevState,
                              { id: item.id, state: true },
                            ]);
                            setLoadedItems((prev) => prev + 1);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
