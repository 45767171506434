import React, { useEffect } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import clsx from "clsx";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { addressTmpState } from "../../stores/addressTmpState";
import { addressesState } from "../../stores/addressesState";
import { Listbox as HeadlessListBox } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
import { initAddress } from "../../utils/utils";
import { editAddress } from "../../actions/backend";
import { ERROR_MSG_PREFIX } from "../../const/error-message";
import { useState } from "react";
import { Spinner } from "../../components/spinner";

const PREFECTURES = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];

export function EditAddress() {
  //エラーの管理
  const [error, setError] = useState({});
  const handleValidation = (newError) => {
    setError((prevError) => ({
      ...prevError,
      ...newError,
    }));
  };

  //郵便番号APIで取得した値を格納するstate
  const [form, setForm] = useState({
    postcode: "",
    prefecture: "",
    city: "",
    town: "",
  });

  //取得したデータをformに反映する
  const handleSetForm = (value) => {
    setForm({ ...form, ...value });
  };

  const { index } = useParams();
  const addresses = useAtomValue(addressesState);
  const setAddressTmp = useSetAtom(addressTmpState);

  useEffect(() => {
    if (!index) return;
    if (index >= addresses.length) return;
    setAddressTmp(addresses[index]);
  }, []);

  return (
    <>
      <div
        className={clsx(
          "flex justify-between items-center",
          "border-b-[1px] p-2",
          "mb-4"
        )}
      >
        <BackButton />
        <div className={clsx("font-bold")}>商品配送先住所を追加</div>
        <div />
      </div>
      <div className={clsx("p-2", "flex flex-col items-center")}>
        <div className={clsx("flex justify-center", "w-[100%]", "mb-8")}>
          <LastNameTextBox className={clsx("w-[50%] max-w-[486px]")} />
          <FirstNameTextBox className={clsx("w-[50%] max-w-[486px]")} />
        </div>
        <div className={clsx("flex justify-center", "w-[100%]", "mb-8")}>
          <LastNameKanaTextBox
            className={clsx("w-[50%] max-w-[486px]")}
            onValidation={handleValidation}
            error={error}
          />
          <FirstNameKanaTextBox
            className={clsx("w-[50%] max-w-[486px]")}
            onValidation={handleValidation}
            error={error}
          />
        </div>
        <div className={clsx("flex justify-center", "w-[100%]", "mb-8")}>
          <PostalCodeTextBox
            className={clsx("w-[50%] max-w-[486px]")}
            onValidation={handleValidation}
            error={error}
            onSetForm={handleSetForm}
          />
          <div className={clsx("w-[50%] max-w-[486px]")} />
        </div>
        <div className={clsx("flex justify-center", "w-[100%]", "mb-8")}>
          <PrefectureTextBox
            className={clsx("w-[50%] max-w-[486px]")}
            form={form}
          />
          <div className={clsx("w-[50%] max-w-[486px]")} />
        </div>
        <AddressDescTextBox
          className={clsx("w-[100%] max-w-[972px]", "mb-8")}
          onValidation={handleValidation}
          error={error}
          form={form}
        />
        <PhoneNumberTextBox
          className={clsx("w-[100%] max-w-[972px]", "mb-8")}
          onValidation={handleValidation}
          error={error}
        />
        <div className={clsx("flex justify-center", "w-[100%]", "mb-8")}>
          <CancelButton className={clsx("w-[50%] max-w-[486px]")} />
          <SaveButton
            className={clsx("w-[50%] max-w-[486px]")}
            index={index}
            error={error}
          />
        </div>
      </div>
    </>
  );
}

const BackButton = (props) => {
  const { className, ...otherProps } = props;
  const setAddressTmp = useSetAtom(addressTmpState);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddressTmp(initAddress());
    navigate("/my-page/address");
  };

  return (
    <button
      className={clsx(className, "")}
      onClick={handleClick}
      {...otherProps}
    >
      <MdKeyboardArrowLeft className={clsx("w-6 h-6")} />
    </button>
  );
};

const TextBox = (props) => {
  const {
    className,
    title,
    value,
    placeholder,
    onChange,
    onValidation = () => {},
    regex = null,
    errorMessage = "",
    error = {},
    name = "",
    loading = null,
    ...otherProps
  } = props;

  //バリデーションチェック
  useEffect(() => {
    if (regex && !regex.test(value) && value) {
      onValidation({ [title]: errorMessage });
    } else {
      onValidation({ [title]: "" });
    }
  }, [value]);

  return (
    <div className={clsx(className, "px-2")} {...otherProps}>
      <div className={clsx("text-start", "pl-1 mb-1")}>
        {title}
        {loading && (
          <Spinner
            className={clsx(
              "inline-block ml-2  h-3 w-3 border-2 border-blue-400 "
            )}
          />
        )}
      </div>
      <input
        className={clsx("border rounded px-4 py-2 w-[100%]", "bg-gray-50")}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
      />
      {error[title] && (
        <div className={clsx("text-red-600 text-left mt-1 text-sm")}>
          {error[title]}
        </div>
      )}
    </div>
  );
};

const ListBox = (props) => {
  const { className, title, selected, onChange, list, ...otherProps } = props;

  return (
    <div className={clsx(className, "px-2")} {...otherProps}>
      <div className={clsx("text-start", "pl-1 mb-1")}>{title}</div>
      <HeadlessListBox value={selected} onChange={onChange}>
        <div className={clsx("relative")}>
          <HeadlessListBox.Button
            className={clsx(
              "border rounded px-4 py-2 w-[100%]",
              "bg-gray-50",
              "flex justify-between items-center"
            )}
          >
            <div>{selected}</div>
            <BiChevronDown className={clsx("w-6 h-6", "text-gray-500")} />
          </HeadlessListBox.Button>
          <HeadlessListBox.Options
            className={clsx(
              "absolute top-[50px]",
              "border rounded px-4 py-2 w-[100%] h-[300px] overflow-y-scroll",
              "bg-gray-50",
              "text-start"
            )}
          >
            {list.map((value, i) => (
              <HeadlessListBox.Option
                className={clsx("cursor-pointer")}
                key={i}
                value={value}
              >
                {value}
              </HeadlessListBox.Option>
            ))}
          </HeadlessListBox.Options>
        </div>
      </HeadlessListBox>
    </div>
  );
};

const LastNameTextBox = (props) => {
  const { className, ...otherProps } = props;
  const [{ lastName }, setAddressTmp] = useAtom(addressTmpState);

  const handleChange = (event) => {
    setAddressTmp((prev) => ({
      ...prev,
      lastName: event.target.value,
    }));
  };

  return (
    <TextBox
      className={clsx(className)}
      title="姓"
      value={lastName}
      placeholder="山田"
      onChange={handleChange}
      {...otherProps}
    />
  );
};

const FirstNameTextBox = (props) => {
  const { className, ...otherProps } = props;
  const [{ firstName }, setAddressTmp] = useAtom(addressTmpState);

  const handleChange = (event) => {
    setAddressTmp((prev) => ({
      ...prev,
      firstName: event.target.value,
    }));
  };

  return (
    <TextBox
      className={clsx(className)}
      title="名"
      value={firstName}
      placeholder="太郎"
      onChange={handleChange}
      {...otherProps}
    />
  );
};

const LastNameKanaTextBox = (props) => {
  const { className, onValidation, error, ...otherProps } = props;
  const [{ lastNameKana }, setAddressTmp] = useAtom(addressTmpState);

  //正規表現とエラーメッセージ
  const regex = /^[ァ-ンヴー]*$/;
  const errorMessage = "全角カタカナで入力してください";

  const handleChange = (event) => {
    setAddressTmp((prev) => ({
      ...prev,
      lastNameKana: event.target.value,
    }));
  };

  return (
    <TextBox
      className={clsx(className)}
      title="姓（カナ）"
      value={lastNameKana}
      placeholder="ヤマダ"
      onChange={handleChange}
      onValidation={onValidation}
      regex={regex}
      errorMessage={errorMessage}
      error={error}
      {...otherProps}
    />
  );
};

const FirstNameKanaTextBox = (props) => {
  const { className, onValidation, error, ...otherProps } = props;
  const [{ firstNameKana }, setAddressTmp] = useAtom(addressTmpState);

  //正規表現とエラーメッセージ
  const regex = /^[ァ-ンヴー]*$/;
  const errorMessage = "全角カタカナで入力してください";

  const handleChange = (event) => {
    setAddressTmp((prev) => ({
      ...prev,
      firstNameKana: event.target.value,
    }));
  };

  return (
    <TextBox
      className={clsx(className)}
      title="名（カナ）"
      value={firstNameKana}
      placeholder="タロウ"
      onChange={handleChange}
      onValidation={onValidation}
      regex={regex}
      errorMessage={errorMessage}
      error={error}
      {...otherProps}
    />
  );
};

const PostalCodeTextBox = (props) => {
  const { className, onValidation, error, onSetForm, ...otherProps } = props;
  const [{ postalCode }, setAddressTmp] = useAtom(addressTmpState);
  const [loading, setLoading] = useState(false);

  //正規表現とエラーメッセージ
  const regex = /^\d{7}$/;
  const errorMessage = "7桁の半角数字をハイフンなしで入力してください";

  //全角英数字を半角英数字に変換する関数
  const convertToHalfWidth = (input) => {
    return input.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  };

  useEffect(() => {
    //入力文字が郵便番号7文字ならAPI実行
    if (postalCode.length === 7) {
      fetchAddressData(postalCode);
    }
  }, [postalCode]);

  //API
  const fetchAddressData = (postcode) => {
    setLoading(true); // データ取得を開始
    fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postcode}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const addressData = data.results[0];
          //APIで取得したデータをformに保存
          onSetForm({
            prefecture: addressData.address1,
            city: addressData.address2,
            town: addressData.address3,
          });
          setLoading(false); // データ取得が終了
        } else {
          setLoading(false); // データ取得が終了
        }
      })
      // eslint-disable-next-line
      .catch((error) => {
        setLoading(false); // データ取得が終了
      });
  };

  //input入力時の挙動
  const handleChange = (event) => {
    //入力中のinputの名前と値を取得
    const { name, value } = event.target;
    //入力中の郵便番号を取得しformに保存
    onSetForm({ [name]: value });
    //入力値を半角に
    const halfWidthValue = convertToHalfWidth(value);

    setAddressTmp((prev) => ({
      ...prev,
      postalCode: halfWidthValue,
    }));
  };

  return (
    <TextBox
      name="postcode"
      className={clsx(className)}
      title="郵便番号"
      value={postalCode}
      placeholder="1234567（ハイフンなし）"
      onChange={handleChange}
      onValidation={onValidation}
      regex={regex}
      errorMessage={errorMessage}
      error={error}
      loading={loading}
      {...otherProps}
    />
  );
};

const PrefectureTextBox = (props) => {
  const { className, form, ...otherProps } = props;
  const [{ prefecture }, setAddressTmp] = useAtom(addressTmpState);

  //APIで取得したstateのデフォルト値をセット
  //郵便番号が変わったらAPIから取得した値に戻す
  useEffect(() => {
    if (form.postcode.length === 7) {
      setAddressTmp((prev) => ({
        ...prev,
        prefecture: form.prefecture,
      }));
    }
  }, [form]);

  //手動で変更する場合
  const handleChange = (value) => {
    setAddressTmp((prev) => ({
      ...prev,
      prefecture: value,
    }));
  };

  return (
    <ListBox
      className={clsx(className)}
      title="都道府県"
      selected={prefecture === "" ? "選択してください" : prefecture}
      onChange={handleChange}
      list={PREFECTURES}
      {...otherProps}
    />
  );
};

const AddressDescTextBox = (props) => {
  const { className, onValidation, error, form, ...otherProps } = props;
  const [{ addressDesc }, setAddressTmp] = useAtom(addressTmpState);

  //正規表現
  const regex =
    /[Ａ-Ｚａ-ｚ０-９！”＃＄％＆’（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝～]/g;

  //全角英数字と全角スペースと全角記号を半角に変更する関数
  const convertToHalfWidth = (input) => {
    const fullWidthSpace = "　";
    const halfWidthSpace = " ";
    const convertedInput = input.replace(
      new RegExp(fullWidthSpace, "g"),
      halfWidthSpace
    );

    return convertedInput.replace(regex, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  };

  //APIで取得したstateのデフォルト値をセット
  //郵便番号が変わったらAPIから取得した値に戻す
  useEffect(() => {
    if (form.postcode.length === 7) {
      setAddressTmp((prev) => ({
        ...prev,
        addressDesc: form.city + form.town,
      }));
    }
  }, [form]);

  //手入力した値でstateのデフォルト値を更新する
  const handleChange = (event) => {
    //半角にする
    const halfWidthValue = convertToHalfWidth(event.target.value);
    //住所のstateを更新
    setAddressTmp((prev) => ({
      ...prev,
      addressDesc: halfWidthValue,
    }));
  };

  return (
    <TextBox
      className={clsx(className, "max-w-[972px]")}
      title="住所"
      value={addressDesc}
      placeholder="架空市夢見町1-23-45 夢のマンション 801号室"
      onChange={handleChange}
      onValidation={onValidation}
      error={error}
      {...otherProps}
    />
  );
};

const PhoneNumberTextBox = (props) => {
  const { className, onValidation, error, ...otherProps } = props;
  const [{ phoneNumber }, setAddressTmp] = useAtom(addressTmpState);

  //正規表現とエラーメッセージ
  const regex = /^(0{1}\d{9,10})$/;
  const errorMessage = "9-10桁の半角数字を入力してください";

  //全角英数字を半角英数字に変換する関数
  const convertToHalfWidth = (input) => {
    return input.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  };

  const handleChange = (event) => {
    const halfWidthValue = convertToHalfWidth(event.target.value);
    setAddressTmp((prev) => ({
      ...prev,
      phoneNumber: halfWidthValue,
    }));
  };

  return (
    <TextBox
      className={clsx(className)}
      title="電話番号"
      value={phoneNumber}
      placeholder="09000000000"
      onChange={handleChange}
      onValidation={onValidation}
      regex={regex}
      errorMessage={errorMessage}
      error={error}
      {...otherProps}
    />
  );
};

const CancelButton = (props) => {
  const { className, ...otherProps } = props;
  const setAddressTmp = useSetAtom(addressTmpState);
  const navigate = useNavigate();

  const handleClick = () => {
    setAddressTmp(initAddress());
    navigate("/my-page/address");
  };

  return (
    <div className={clsx(className, "px-2 w-[100%]")}>
      <button
        className={clsx(
          "rounded px-4 py-2 w-[100%]",
          "bg-gray-200",
          "font-bold",
          "hover:opacity-70"
        )}
        onClick={handleClick}
        {...otherProps}
      >
        キャンセル
      </button>
    </div>
  );
};

const SaveButton = (props) => {
  const { className, index, error, ...otherProps } = props;
  const [addressTmp, setAddressTmp] = useAtom(addressTmpState);
  const setAddresses = useSetAtom(addressesState);
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      await editAddress(index, addressTmp);
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
    setAddresses((prev) => {
      const newAddresses = [...prev];
      newAddresses[index] = addressTmp;
      return newAddresses;
    });
    setAddressTmp(initAddress());
    navigate("/my-page/address");
  };

  const canAddAddress = () => {
    return (
      addressTmp.firstName !== "" &&
      addressTmp.lastName !== "" &&
      addressTmp.firstNameKana !== "" &&
      addressTmp.lastNameKana !== "" &&
      addressTmp.postalCode !== "" &&
      addressTmp.prefecture !== "" &&
      addressTmp.addressDesc !== "" &&
      addressTmp.phoneNumber !== ""
    );
  };

  return (
    <div className={clsx(className, "px-2 w-[100%]")}>
      <button
        className={clsx(
          "rounded px-4 py-2 w-[100%]",
          "bg-gray-200",
          "font-bold",
          "hover:opacity-70",
          "disabled:opacity-50"
        )}
        //空のフォームがあるかつerrorオブジェクトにエラーが残っているときはdisabledを有効にする
        disabled={
          !canAddAddress() ||
          !Object.values(error).every((error) => error === "")
        }
        onClick={handleClick}
        {...otherProps}
      >
        保存
      </button>
    </div>
  );
};
