import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../../package/firebase";
import { getPoint } from "../../actions/point";
import { PRICES } from "../../const/const";

export function PointList() {
  const navigate = useNavigate();
  const [user] = useAuthState(fireauth);
  const [point, setPoint] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPoint = async () => {
      if (!user) return;
      try {
        const pointOfCurrentUser = await getPoint(user.uid);
        if (pointOfCurrentUser) setPoint(pointOfCurrentUser);
      } catch (error) {
        console.error("getPoint error occurred: ", error);
      }
    };
    fetchPoint();
  }, [user]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={clsx(
        "flex justify-center",
        "w-full min-h-screen",
        "p-2 bg-slate-200"
      )}
    >
      <div
        className={clsx(
          "max-w-[800px] mx-auto flex-1 flex flex-col items-start"
        )}
      >
        <button
          className={clsx(
            "bg-gray-400 text-white font-bold py-0 px-1 rounded-full"
          )}
          onClick={() => handleGoBack()}
        >
          ＜ 戻る
        </button>
        <div className={clsx("w-full p-4 flex items-end justify-between")}>
          <h1 className={clsx("text-3xl font-bold")}>ポイント購入</h1>
          <div className={clsx("flex flex-col items-end")}>
            <p className={clsx("text-xs m-0 font-bold")}>所持ポイント</p>
            <h3 className={clsx("text-md font-bold")}>{point} points</h3>
          </div>
        </div>
        <div className={clsx("w-full p-4 flex flex-col gap-4")}>
          {PRICES.map((price, index) => (
            <div
              key={index}
              className={clsx(
                "w-full flex justify-between sm:justify-around items-center bg-white p-4 rounded-lg"
              )}
            >
              <div className={clsx("flex flex-col")}>
                <p className={clsx("text-md font-bold")}>{price} points</p>
                <h3 className={clsx("text-xs m-0")}>{price}円で購入</h3>
              </div>
              <button
                className="bg-black hover:bg-jgs-main-hovered text-yellow-300 font-bold py-2 px-8 rounded-full"
                onClick={() => navigate(`/payment/${price}`)}
              >
                購入する
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
