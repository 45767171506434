import { atom } from "jotai";

/**
 * 編集中の住所の状態
 */
export const addressTmpState = atom({
  firstName: "",
  lastName: "",
  firstNameKana: "",
  lastNameKana: "",
  postalCode: "",
  prefecture: "",
  addressDesc: "",
  phoneNumber: "",
  isSelected: true,
});
