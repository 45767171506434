import React, { useState, useEffect } from "react";
import AdminHeader from "../../components/header-admin";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineClose,
  MdUpload,
  MdEdit,
} from "react-icons/md";
import clsx from "clsx";
import Mask from "../../components/Mask";
import ModalCard from "../../components/ModalCard";
import {
  getPackages,
  addPackage,
  shipCard,
  cancelShipment,
  getCardList,
  editPoint,
} from "../../actions/backend";
import {
  retrieveSpecificStatus,
  retrieveHeldCard,
  displayJaStatus,
  displayJaStatusForWithdraw,
  transformData,
} from "../../utils/history";
import { getAllUsers } from "../../actions/user";
import { fetchSpreadsheetData } from "../../actions/card";
import { checkAdminUser } from "../../actions/user";
import { useNavigate } from "react-router-dom";
import { imgUpload } from "../../actions/image";
import { onAuthStateChanged } from "firebase/auth";
import { fireauth } from "../../package/firebase";
import JGSLoaing from "../../components/JGSLoaing";

// eslint-disable-next-line
const MAX_ITEMS_PER_REQUEST = 420;

export function Admin() {
  const navigate = useNavigate();
  const [selectedTag, setSelectedTag] = useState("gacha");
  const [isOpenMoodal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  // eslint-disable-next-line
  const [items, setItems] = useState(null); // シートから取得したアイテム一覧
  // eslint-disable-next-line
  const [sheetLink, setSheetLink] = useState("");
  const [onLoad, setOnLoad] = useState(false);
  const [onError, setOnError] = useState(false);
  const [cardId, setCardId] = useState("");
  const [holderId, setHolderId] = useState("");
  const [packageName, setPackageName] = useState("");
  const [packageDetail, setPackageDetail] = useState("");
  const [packagePrice, setPackagePrice] = useState(100);
  const [packageImage, setPackageImage] = useState("");
  const [packageTags, setPackageTags] = useState([]);
  const [packageImgURL, setPackageImgURL] = useState("");
  const [packageGSSSheetName, setPackageGSSSheetName] = useState("");
  const [invoiceNumbers, setInvoiceNumbers] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [gachaIds, setGachaIds] = useState([]);
  const [hitList, setHitList] = useState([]);
  // eslint-disable-next-line
  const [allHitList, setAllHitList] = useState([]);
  const [unshippedCardList, setunShippedCardList] = useState([]);
  const [shippedCardList, setShippedCardList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [selectedCardValue, setSelectedCardValue] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUserUuid, setCurrentUserUuid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      localStorage.getItem("packageName") &&
      localStorage.getItem("packageTags") &&
      localStorage.getItem("packageDetail") &&
      localStorage.getItem("packagePrice")
    ) {
      setPackageName(JSON.parse(localStorage.getItem("packageName")));
      setPackageDetail(JSON.parse(localStorage.getItem("packageDetail")));
      setPackagePrice(JSON.parse(localStorage.getItem("packagePrice")));
      setPackageTags(JSON.parse(localStorage.getItem("packageTags")));
      setPackageGSSSheetName(
        JSON.parse(localStorage.getItem("packageGSSSheetName"))
      );
      setIsOpenModal(true);
      setModalType("newPackage");
      localStorage.removeItem("packageName");
      localStorage.removeItem("packageTags");
      localStorage.removeItem("packageDetail");
      localStorage.removeItem("packagePrice");
      localStorage.removeItem("packageGSSSheetName");
    }
    const listener = onAuthStateChanged(fireauth, async (user) => {
      if (!user) {
        alert("ログインしてください。");
        navigate("/");
        return;
      }
      setIsAuthenticated(!!user);
      setCurrentUserUuid(user.uid);
    });
    return () => {
      listener();
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;
    const adminChecker = async () => {
      const isAdmin = await checkAdminUser(currentUserUuid);
      if (!isAdmin) navigate("/");
      const allUser = await getAllUsers();
      setUserList(allUser);
    };
    adminChecker();
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchData = async () => {
      window.scrollTo(0, 0);
      setIsLoading(true);
      if (selectedTag === "gacha") {
        try {
          const res = await getPackages();
          const packageList = res["gachaList"];
          const sortedGachaList = packageList
            .slice()
            .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds);
          setPackageList(sortedGachaList);
          let gachaIdsList = [];
          sortedGachaList.map((gacha) => {
            gachaIdsList.push(gacha.id);
          });
          setGachaIds(gachaIdsList);
        } catch (error) {
          console.error("Gacha data fetch failed:", error);
        }
      } else if (selectedTag === "shipping-request") {
        const tmp = Object.values(hitList).reduce(
          (acc, currentValue) => acc.concat(currentValue),
          []
        );
        setAllHitList(tmp);
        const tmp2 = retrieveSpecificStatus(tmp, "shipping-request");
        setunShippedCardList(tmp2);
      } else if (selectedTag === "shipped") {
        const tmp = Object.values(hitList).reduce(
          (acc, currentValue) => acc.concat(currentValue),
          []
        );
        setAllHitList(tmp);
        const tmp2 = retrieveSpecificStatus(tmp, "shipped");
        setShippedCardList(tmp2);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [selectedTag]);

  useEffect(() => {
    let isSubscribed = true; // コンポーネントの生存を追跡するフラグ
    const fetchHitList = async () => {
      const fetchPromises = gachaIds.map((gachaId) => getCardList(gachaId));
      try {
        const dataArr = await Promise.all(fetchPromises);
        if (isSubscribed) {
          // まだマウントされている場合のみ状態を更新
          const newHitList = {};
          dataArr.forEach((data, index) => {
            const gachaId = gachaIds[index];
            newHitList[gachaId] = data["cardList"];
          });
          setHitList(newHitList);
        }
      } catch (error) {
        console.error("Error fetching hit lists:", error);
        // エラー処理をここで実施する
      }
    };
    fetchHitList();
    return () => {
      isSubscribed = false; // コンポーネントがアンマウントされたらフラグを下ろす
    };
  }, [gachaIds.join(",")]); // 依存配列には配列の内容に基づく文字列を使用

  // handlers
  const handlePackageNameChange = (event) => setPackageName(event.target.value);
  const handlePackageDetailChange = (event) =>
    setPackageDetail(event.target.value);
  const handlePackagePriceChange = (event) =>
    setPackagePrice(event.target.value);
  const handlePackageImageChange = async (event) => {
    setPackageImage(event.target.files[0]); // Assuming you want to store the file object
    const url = await imgUpload(event.target.files[0]);
    if (url) {
      setPackageImgURL(url);
    }
  };
  const handlePackageTagsChange = (event) => {
    // カンマで区切られた文字列を配列に変換
    const tags = event.target.value.split(",").map((tag) => tag.trim());
    setPackageTags(tags);
  };
  const handlePackageGSSSheetNameChange = (event) =>
    setPackageGSSSheetName(event.target.value);
  const handleInvoiceNumberChange = (index, value) => {
    setInvoiceNumbers((prev) => ({ ...prev, [index]: value }));
  };
  const handleSelectedCardValueChange = (event) =>
    setSelectedCardValue(event.target.value);

  const [openedCardIndex, setOpenedCardIndex] = useState(null);

  const toggleCard = (index) => {
    if (openedCardIndex === index) {
      // もしクリックされたカードが既に開いている場合は閉じる
      setOpenedCardIndex(null);
    } else {
      // そうでなければ、クリックされたカードを開く
      setOpenedCardIndex(index);
    }
  };

  const closeModal = () => {
    setItems(null);
    setOnError(false);
    setIsOpenModal(false);
    setModalType("");
  };

  // 新規ガチャ追加
  const createNewPackage = async () => {
    setIsLoading(true);
    try {
      let items = await fetchItems(); // Capture the returned items from fetchItems.
      items.shift(); // Remove the first element of the array.
      const parsedItems = items; // Transform the data.
      if (
        packageName === "" ||
        packageDetail === "" ||
        packagePrice === 0 ||
        packageImage === "" ||
        packageImgURL === "" ||
        packageTags.length === 0 ||
        packageGSSSheetName === "" ||
        !parsedItems
      ) {
        alert("全て必須入力項目です。");
        setIsLoading(false);
        return;
      }
      const res = await addPackage(
        packageName,
        packageDetail,
        packagePrice,
        packageImgURL,
        packageTags,
        parsedItems
      );
      res.packageId
        ? alert("新規ガチャが追加されました。")
        : alert(
            "新規ガチャ追加時にエラーが発生しました。運営にお問い合わせください。"
          );
      setIsLoading(false);
      closeModal();
    } catch (error) {
      console.error("Error occurred while creating a new package: ", error);
      alert(
        "新規ガチャ追加時にエラーが発生しました。運営にお問い合わせください。"
      );
      setIsLoading(false);
      closeModal();
    }
  };

  const fetchItems = async () => {
    if (packageGSSSheetName === "") return null; // Return null immediately if condition is met.
    setItems(null);
    setOnError(false);
    setOnLoad(true);
    const res = await fetchSpreadsheetData(packageGSSSheetName);
    let fetchedItems = null; // Initialize fetchedItems to null.
    if (res?.values === undefined) {
      setOnError(true);
    } else {
      let index = 0;
      let tempItems = [];
      for (const item of res.values) {
        if (index !== 1 && item.length === 10) {
          tempItems.push(item);
        }
        index += 1;
      }
      fetchedItems = tempItems; // Assign the fetched items to the variable.
      setItems(fetchedItems); // Update the state with the fetched items.
    }
    setOnLoad(false);
    return fetchedItems; // Return the fetched items.
  };

  // 発送済みにする
  const updateCardStatusShip = async (holderId, cardId, invoiceNumber) => {
    setIsLoading(true);
    try {
      const iv = invoiceNumber ? invoiceNumber : "";
      const res = await shipCard(holderId, cardId, iv);
      res.holderId,
        res.contentId
          ? alert("「発送済み」に変更されました。")
          : alert(
              "「発送済み」に変更時にエラーが発生しました。運営にお問い合わせください。"
            );
    } catch (error) {
      console.error("Error occurred while creating a new package: ", error);
      alert(
        "「発送済み」に変更時にエラーが発生しました。運営にお問い合わせください。"
      );
    }
    setIsLoading(false);
    closeModal();
    window.location.reload();
  };

  // 未発送にする
  const updateCardStatusUnship = async (holderId, cardId) => {
    setIsLoading(true);
    try {
      const res = await cancelShipment(holderId, cardId);
      res.holderId,
        res.contentId
          ? alert("「未発送」に変更されました。")
          : alert(
              "「未発送」に変更時にエラーが発生しました。運営にお問い合わせください。"
            );
    } catch (error) {
      console.error("Error occurred while creating a new package: ", error);
      alert(
        "「未発送」に変更時にエラーが発生しました。運営にお問い合わせください。"
      );
    }
    setIsLoading(false);
    closeModal();
    window.location.reload();
  };

  // ポイント更新
  const updateCardPoint = async () => {
    selectedCard.value = selectedCardValue;
    // eslint-disable-next-line
    const res = await editPoint(
      selectedCard.packageId,
      selectedCard.id,
      selectedCardValue
    );
    setSelectedCardValue(0);
    setSelectedCard({});
    closeModal();
  };

  // go preview
  const goPreview = async () => {
    if (packageImgURL === "" || !packageImgURL) {
      alert(
        "メインビジュアルをアップロードしてください。画像をアップロードして数秒待ってからもう一度お試しください。"
      );
      return;
    }
    let items = await fetchItems(); // Capture the returned items from fetchItems.
    items.shift(); // Remove the first element of the array.
    const parsedItems = transformData(items); // Transform the data.
    if (
      packageName === "" ||
      packageDetail === "" ||
      packagePrice === 0 ||
      packageImage === "" ||
      packageImgURL === "" ||
      !packageImgURL ||
      packageTags.length === 0 ||
      packageGSSSheetName === "" ||
      !parsedItems
    ) {
      alert("全て必須入力項目です。");
      return;
    }
    localStorage.setItem("packageName", JSON.stringify(packageName));
    localStorage.setItem("packageDetail", JSON.stringify(packageDetail));
    localStorage.setItem("packagePrice", JSON.stringify(packagePrice));
    localStorage.setItem("packageTags", JSON.stringify(packageTags));
    localStorage.setItem(
      "packageGSSSheetName",
      JSON.stringify(packageGSSSheetName)
    );
    navigate("/admin/preview", {
      state: {
        imageUrl: packageImgURL,
        price: packagePrice,
        cardList: parsedItems,
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-300">
      {isLoading && <JGSLoaing />}
      <AdminHeader selectedTag={selectedTag} setSelectedTag={setSelectedTag} />
      <div className="max-w-[1050px] mx-auto p-4 pt-36 grid gap-4 grid-cols-1 justify-items-center place-items-start">
        {/* ▼ ガチャ管理 */}
        {selectedTag === "gacha" && (
          <>
            <div className="w-full h-16 rounded-xl bg-white flex justify-center items-center">
              <p
                className="text-sm font-bold text-jgs-admin"
                onClick={() => {
                  setPackageName("");
                  setPackageDetail("");
                  setPackagePrice(100);
                  setPackageImage("");
                  setPackageTags([]);
                  setPackageGSSSheetName("");
                  setIsOpenModal(true);
                  setModalType("newPackage");
                }}
              >
                + 新規ガチャ追加
              </p>
            </div>
            {packageList.map((item, index) => (
              <div
                key={index}
                className="w-full rounded-xl bg-white overflow-hidden"
              >
                <div
                  className="w-full h-16 px-8 flex justify-between items-center"
                  onClick={() => toggleCard(index)} // クリックで開閉を切り替える
                >
                  <p className="text-sm font-bold text-black">{item.name}</p>
                  <div className="flex items-center">
                    <p className="text-base font-bold text-black">
                      {hitList[item.id]
                        ? retrieveHeldCard(hitList[item.id]).length
                        : 0}
                    </p>
                    <div className="flex flex-col">
                      <p className="text-xs text-gray-500">ガチャ回数</p>
                      <p className="text-xs text-gray-500">
                        /{hitList[item.id] ? hitList[item.id].length : 0}回
                      </p>
                    </div>
                    {openedCardIndex === index ? (
                      <MdKeyboardArrowUp className="w-5 h-5 ml-2 text-gray-500" />
                    ) : (
                      <MdKeyboardArrowDown className="w-5 h-5 ml-2 text-gray-500" />
                    )}
                  </div>
                </div>
                {openedCardIndex === index && (
                  <div className="border-t border-gray-200 p-4">
                    {/* {isEditing ? (
                      <button
                        className="btn-primary mb-4"
                        style={{ width: "50%" }}
                        onClick={saveChanges}
                      >
                        保存
                      </button>
                    ) : (
                      <button
                        className="btn-primary mb-4"
                        style={{ width: "50%" }}
                        onClick={() => {
                          // setPackageId(item.id);
                          // setPackageName(item.name);
                          // setPackageDetail(item.detail);
                          // setPackagePrice(item.price);
                          // setPackageImage(item.img);
                          // setPackageTags(item.tags);
                          // setPackageGSSSheetName(item.id);
                          // setIsOpenModal(true);
                          // setModalType("editPackage");
                          setIsEditing(true); // 編集モードを開始
                        }}
                      >
                        編集
                      </button>
                    )} */}
                    <div className="grid grid-cols-1 gap-4">
                      {hitList[item.id] &&
                        hitList[item.id].map((card, index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center"
                          >
                            <div className="w-1/5 text-xs text-black flex justify-end truncate ">
                              <p>{card.value}pt</p>
                              {card.holderId === "" && (
                                <MdEdit
                                  className="ml-1 text-base"
                                  onClick={() => {
                                    setSelectedCard(card);
                                    setIsOpenModal(true);
                                    setModalType("editPoint");
                                  }}
                                />
                              )}
                            </div>
                            <p className="text-xs text-black w-1/4 truncate">
                              {card.name}
                            </p>
                            <p
                              className={`text-xs w-1/4 truncate ${
                                displayJaStatus(card.holderId) === "当選済み"
                                  ? "text-red-500"
                                  : "text-gray-500"
                              }`}
                            >
                              {displayJaStatus(card.holderId)}
                            </p>
                            <p
                              className={`text-xs w-1/4 truncate ${
                                displayJaStatusForWithdraw(card.status) ===
                                "未発送"
                                  ? "text-red-500"
                                  : "text-black"
                              }`}
                            >
                              {displayJaStatusForWithdraw(card.status)}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        )}
        {/* ▲ ガチャ管理 */}

        {/* ▼ 発送待ち */}
        {selectedTag === "shipping-request" && (
          <>
            <div className="w-full flex pl-8">
              <p className="text-lg font-bold text-black">未発送アイテム一覧</p>
            </div>
            {unshippedCardList.map((item, index) => (
              <div
                key={index}
                className="w-full px-4 py-2 rounded-xl bg-white flex flex-col"
              >
                <div className="flex justify-between items-center">
                  <p className="text-base font-bold text-black">{item.name}</p>
                  {/* invoiceNumber */}
                  <p className="text-sm font-bold text-gray-500">
                    {item.invoiceNumber}
                  </p>
                </div>
                <div className="flex items-start">
                  <img
                    src={item.img}
                    alt={`Item ${index}`}
                    className="w-1/4 object-contain"
                  />
                  <div className="flex flex-col items-start flex-wrap ml-2">
                    {/* ▼ holderの詳細情報 */}
                    <p className="break-all text-base text-start font-bold text-black">
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map((filteredUser) => filteredUser.name)}
                    </p>
                    <p className="break-all text-base text-start font-bold text-black">
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map(
                            (filteredUser) =>
                              filteredUser.addresses &&
                              filteredUser.addresses[0] &&
                              "〒" + filteredUser.addresses[0].postalCode + " "
                          )}
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map(
                            (filteredUser) =>
                              filteredUser.addresses &&
                              filteredUser.addresses[0] &&
                              filteredUser.addresses[0].prefecture
                          )}
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map(
                            (filteredUser) =>
                              filteredUser.addresses &&
                              filteredUser.addresses[0] &&
                              filteredUser.addresses[0].addressDesc
                          )}
                    </p>
                    <p className="break-all text-base text-start font-bold text-black">
                      TEL:{" "}
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map(
                            (filteredUser) =>
                              filteredUser.addresses &&
                              filteredUser.addresses[0] &&
                              filteredUser.addresses[0].phoneNumber
                          )}
                    </p>
                    {/* ▲ */}
                    <p className={clsx("input-title-main")}>
                      送り状番号（任意）
                    </p>
                    <input
                      className={clsx("input-main w-full")}
                      type="text"
                      placeholder="1234-5678-9012"
                      value={invoiceNumbers[index] || ""} // 初期値は空文字列
                      onChange={(e) =>
                        handleInvoiceNumberChange(index, e.target.value)
                      }
                    />
                    <button
                      className="btn-primary mt-2"
                      onClick={() => {
                        console.dir(item);
                        setHolderId(item.holderId);
                        setCardId(item.id);
                        setInvoiceNumber(invoiceNumbers[index]);
                        setIsOpenModal(true);
                        setModalType("shipCard");
                      }}
                    >
                      発送済みにする
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        {/* ▲ 発送待ち */}

        {/* ▼ 発送済み */}
        {selectedTag === "shipped" && (
          <>
            <div className="w-full flex pl-8">
              <p className="text-lg font-bold text-black">
                発送済みアイテム一覧
              </p>
            </div>
            {shippedCardList.map((item, index) => (
              <div
                key={index}
                className="w-full px-4 py-2 rounded-xl bg-white flex flex-col"
              >
                <div className="flex justify-between items-center flex-wrap">
                  <p className="text-base font-bold text-black">{item.name}</p>
                  {/* invoiceNumber */}
                  <p className="text-sm font-bold text-gray-500">
                    {item.invoiceNumber}
                  </p>
                </div>
                <div className="flex items-start">
                  <img
                    src={item.img}
                    alt={`Item ${index}`}
                    className="w-1/4 object-contain"
                  />
                  <div className="flex flex-col items-start flex-wrap ml-2">
                    {/* ▼ holderの詳細情報 */}
                    <p className="break-all text-base text-start font-bold text-black">
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map((filteredUser) => filteredUser.name)}
                    </p>
                    <p className="break-all text-base text-start font-bold text-black">
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map(
                            (filteredUser) =>
                              filteredUser.addresses &&
                              filteredUser.addresses[0] &&
                              "〒" + filteredUser.addresses[0].postalCode + " "
                          )}
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map(
                            (filteredUser) =>
                              filteredUser.addresses &&
                              filteredUser.addresses[0] &&
                              filteredUser.addresses[0].prefecture
                          )}
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map(
                            (filteredUser) =>
                              filteredUser.addresses &&
                              filteredUser.addresses[0] &&
                              filteredUser.addresses[0].addressDesc
                          )}
                    </p>
                    <p className="break-all text-base text-start font-bold text-black">
                      TEL:{" "}
                      {userList &&
                        userList
                          .filter((user) => user.uid === item.holderId)
                          .map(
                            (filteredUser) =>
                              filteredUser.addresses &&
                              filteredUser.addresses[0] &&
                              filteredUser.addresses[0].phoneNumber
                          )}
                    </p>
                    {/* ▲ */}
                    <p className={clsx("input-title-main")}>
                      送り状番号（任意）
                    </p>
                    <input
                      className={clsx("input-main w-full")}
                      type="text"
                      placeholder="1234-5678-9012"
                      value={invoiceNumbers[index] || ""} // 初期値は空文字列
                      onChange={(e) =>
                        handleInvoiceNumberChange(index, e.target.value)
                      }
                      readOnly
                    />
                    <button
                      className="btn-cancel mt-2"
                      onClick={() => {
                        setHolderId(item.holderId);
                        setCardId(item.id);
                        setIsOpenModal(true);
                        setModalType("unshipCard");
                      }}
                    >
                      未発送にする
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        {/* ▲ 発送済み */}
      </div>

      {/* ▼ Modal */}
      {isOpenMoodal && modalType === "newPackage" && (
        <Mask closeModal={closeModal}>
          <ModalCard closeModal={closeModal}>
            <div className={clsx("flex justify-end", "w-full")}>
              <MdOutlineClose
                className="w-5 h-5 ml-2 text-gray-500"
                onClick={closeModal}
              />
            </div>
            <h4 className="text-lg font-bold text-black">新規ガチャ追加</h4>
            <p className={clsx("input-title-main")}>ガチャの名前</p>
            <input
              className={clsx("input-main")}
              type="text"
              placeholder="〇〇ガチャ"
              value={packageName}
              onChange={handlePackageNameChange}
            />
            <p className={clsx("input-title-main")}>ガチャの詳細</p>
            <input
              className={clsx("input-main")}
              type="text"
              placeholder="abcdefg"
              value={packageDetail}
              onChange={handlePackageDetailChange}
            />
            <p className={clsx("input-title-main")}>消費コイン数/1回</p>
            <input
              className={clsx("input-main")}
              type="number" // 数字入力の場合はtypeをnumberに設定
              placeholder="100"
              value={packagePrice}
              onChange={handlePackagePriceChange}
            />
            <p className={clsx("input-title-main")}>タグ</p>
            {/* inputフィールドのvalueはカンマ区切りの文字列にする */}
            <input
              className={clsx("input-main")}
              type="text"
              placeholder="タグをカンマ区切りで入力"
              value={packageTags.join(", ")} // 配列をカンマ区切りの文字列に変換
              onChange={handlePackageTagsChange}
            />
            <p className={clsx("input-title-main")}>メインビジジュアル</p>
            <input
              className={clsx("input-main")}
              type="file"
              accept="image/*"
              onChange={handlePackageImageChange}
              style={{ display: "none" }}
              id="image-upload"
            />
            <label
              htmlFor="image-upload"
              className={clsx("input-main", "flex items-center")}
            >
              <MdUpload className="w-5 h-5 mr-2 text-gray-500"></MdUpload>
              {packageImage.name || "画像を選択してください"}
            </label>
            <p className={clsx("input-title-main")}>アイテム一覧（シート名）</p>
            <input
              className={clsx("input-main mb-4")}
              type="text"
              placeholder="sample"
              value={packageGSSSheetName}
              onChange={handlePackageGSSSheetNameChange}
            />
            {onLoad && (
              <div className="flex h-10 w-10" aria-label="読み込み中">
                <div className="animate-ping h-4 w-4 bg-blue-600 rounded-full"></div>
              </div>
            )}
            {onError && (
              <p className={clsx("input-title-main")}>
                アイテムを取得できませんでした。シート名をご確認ください。
              </p>
            )}
            {/* {!onError && items !== null && (
              <p className={clsx("input-title-main")}>
                {items.map((subArray, idx) => (
                  <div key={idx}>
                    {subArray.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </div>
                ))}
              </p>
            )} */}
            <button
              className="btn-primary mb-2"
              onClick={() => {
                goPreview();
              }}
            >
              プレビュー確認
            </button>
            <button
              className="btn-admin"
              onClick={() => {
                createNewPackage();
              }}
            >
              新規ガチャ追加
            </button>
          </ModalCard>
        </Mask>
      )}
      {isOpenMoodal && modalType === "editPoint" && (
        <Mask closeModal={closeModal}>
          <ModalCard closeModal={closeModal}>
            <div className={clsx("flex justify-end", "w-full")}>
              <MdOutlineClose
                className="w-5 h-5 ml-2 text-gray-500"
                onClick={closeModal}
              />
            </div>
            <h4 className="text-lg font-bold text-black">ポイント更新</h4>
            <p className={clsx("input-title-main")}>還元されるポイント</p>
            <input
              className={clsx("input-main mb-4")}
              type="number" // 数字入力の場合はtypeをnumberに設定
              placeholder="100"
              value={selectedCardValue}
              onChange={handleSelectedCardValueChange}
            />
            <button
              className="btn-primary"
              onClick={() => {
                updateCardPoint();
              }}
            >
              保存
            </button>
          </ModalCard>
        </Mask>
      )}
      {isOpenMoodal && modalType === "shipCard" && (
        <Mask closeModal={closeModal}>
          <ModalCard closeModal={closeModal}>
            <div className={clsx("flex justify-end", "w-full")}>
              <MdOutlineClose
                className="w-5 h-5 ml-2 text-gray-500"
                onClick={closeModal}
              />
            </div>
            <h4 className="mb-4 text-lg font-bold text-black">
              発送済みにしますか？
            </h4>
            <button
              className="btn-primary mb-2"
              onClick={() => {
                updateCardStatusShip(holderId, cardId, invoiceNumber);
              }}
            >
              発送済みにする
            </button>
            <button className="btn-cancel" onClick={closeModal}>
              キャンセル
            </button>
          </ModalCard>
        </Mask>
      )}
      {isOpenMoodal && modalType === "unshipCard" && (
        <Mask closeModal={closeModal}>
          <ModalCard closeModal={closeModal}>
            <div className={clsx("flex justify-end", "w-full")}>
              <MdOutlineClose
                className="w-5 h-5 ml-2 text-gray-500"
                onClick={closeModal}
              />
            </div>
            <h4 className="text-lg font-bold text-black mb-4">
              未発送に戻しますか？
            </h4>
            <button
              className="btn-primary mb-2"
              onClick={() => {
                updateCardStatusUnship(holderId, cardId);
              }}
            >
              未発送に戻す
            </button>
            <button className="btn-cancel" onClick={closeModal}>
              キャンセル
            </button>
          </ModalCard>
        </Mask>
      )}
      {/* ▲ Modal */}
    </div>
  );
}

export default Admin;
