import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import logoJGS from "../assets/JGS_logo1.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../package/firebase";
import BtnBack from "./btn-back";

export const AdminHeader = ({ selectedTag, setSelectedTag }) => {
  // eslint-disable-next-line
  const [user, loading] = useAuthState(fireauth);
  // eslint-disable-next-line
  const [point, setPoint] = useState(0);
  const tagList = [
    {
      key: "gacha",
      value: "ガチャ管理",
    },
    {
      key: "shipping-request",
      value: "発送待ち",
    },
    {
      key: "shipped",
      value: "発送済み",
    },
  ];

  return (
    <header
      className={clsx(
        "flex justify-center",
        "w-full",
        "p-2",
        "bg-jgs-admin",
        "fixed z-30"
      )}
    >
      <div className={clsx("flex flex-col", "w-full max-w-[1060px]")}>
        <div className={clsx("flex justify-between items-center")}>
          <div className={clsx("flex items-center")}>
            <Logo />
            <h2 className={clsx("text-white ml-2 font-bold")}>管理画面</h2>
          </div>
          <BtnBack destination="/" />
        </div>

        <div className="my-2 flex gap-4 overflow-x-auto whitespace-nowrap">
          {tagList.map((tag, index) => (
            <div
              key={index}
              className={clsx(
                "btn-tag",
                selectedTag === tag.key ? "btn-tag-active" : "btn-tag-default"
              )}
              onClick={() => setSelectedTag(tag.key)}
            >
              {tag.value}
            </div>
          ))}
        </div>
      </div>
    </header>
  );
};

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx("cursor-pointer", "text-2xl")}
      onClick={() => {
        navigate("/");
      }}
    >
      <img src={logoJGS} className={clsx("w-10 h-10")} alt="logo" />
    </div>
  );
};

export default AdminHeader;
