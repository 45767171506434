import React from "react";

const JGSLoaing = () => {
  return (
<div
  className="z-50 fixed inset-0 flex items-center justify-center"
  // style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", pointerEvents: "none" }}
  style={{ backgroundColor: "rgba(0, 0, 0, 0.5)"}}
  aria-label="カードデータ取得中…。"
>
  <div
    className="flex justify-center items-center backdrop-filter backdrop-blur-sm"
    // style={{ pointerEvents: "auto" }}
  >
    <div className="animate-spin h-9 w-9 border-4 border-blue-400 rounded-full border-t-transparent" />
    <div className="text-gray-50 m-2 text-xl font-semibold">
      {"カードデータ取得中…。"}
    </div>
  </div>
</div>


  );
};

export default JGSLoaing;
