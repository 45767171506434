import React from "react";
import clsx from "clsx";

export function PointComplete(props) {
  return (
    <div
      className={clsx(
        "w-full h-[182px] md:h-[198px] flex items-center justify-center flex-col"
      )}
    >
      <div className="w-full flex flex-col items-center gap-4">
        <h1 className={clsx("text-[24px] leading-tight font-bold")}>
          ポイント還元しました
        </h1>
        <p className={clsx("text-xs m-0 font-bold")}>
          還元したポイントは、保有ポイントに加算されました。
        </p>
        <button
          className="w-56 bg-blue-800 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded-full"
          onClick={() => {
            props.setFooterNum(0);
            if (props.setOpenResultModal) props.setOpenResultModal(false);
            if (props.setPointUpdated) props.setPointUpdated(true);
            if (props.setNeedToUpdateCardList)
              props.setNeedToUpdateCardList(true);
          }}
        >
          戻る
        </button>
      </div>
    </div>
  );
}
