import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../../package/firebase";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { getCardListHistory } from "../../actions/card-list-history";
import { retrieveSpecificStatus } from "../../utils/history";
import BtnBackTransparent from "../../components/btn-back-transparent";
import HistoryFooter from "../../components/history/HistoryFooter";
import JGSLoaing from "../../components/JGSLoaing";
import { GeneralHeader } from "../../components/header-simple";
import { useSetAtom } from "jotai";
import { isOpenMyPageState } from "../../stores/isOpenMyPageState";

export function History() {
  const isOpenMyPage = useSetAtom(isOpenMyPageState);
  const [user, loading, error] = useAuthState(fireauth);
  const navigate = useNavigate();
  const [selectedTag, setSelectedTag] = useState("yet");
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalValOfselectedItems, setTotalValOfselectedItems] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [filteredResultList, setFilteredResultList] = useState([]);
  const [needToUpdateCardList, setNeedToUpdateCardList] = useState(false);
  const [pointUpdated, setPointUpdated] = useState(false);
  // footer
  const [footerNum, setFooterNum] = useState(0);
  const tagList = [
    {
      key: "yet",
      value: "未選択",
    },
    {
      key: "shipping-request",
      value: "発送待ち",
    },
    {
      key: "shipped",
      value: "発送済み",
    },
    {
      key: "exchanged",
      value: "ポイント交換済み",
    },
  ];

  useEffect(() => {
    isOpenMyPage(false);
    if (localStorage.getItem("rootPage")) {
      localStorage.removeItem("rootPage");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (setResultList.length >= 1) {
      const tmp = retrieveSpecificStatus(resultList, selectedTag);
      setFilteredResultList(tmp);
    }
  }, [selectedTag]);

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.error("Authentication error: ", error);
      return;
    }
    if (!user) {
      alert("ログインしていません");
      navigate("/");
    }
    const fetchCardListHistory = async () => {
      try {
        const data = await getCardListHistory();
        const cardListHistory = data.cardListHistory;
        setResultList(cardListHistory);
        const retrieved = retrieveSpecificStatus(cardListHistory, selectedTag);
        setFilteredResultList(retrieved);
      } catch (error) {
        console.error("getCardListHistory error occurred: ", error);
      }
    };
    fetchCardListHistory();
  }, [user, loading, error]);

  useEffect(() => {
    if (!needToUpdateCardList) return;
    const fetchCardListHistory = async () => {
      try {
        const data = await getCardListHistory();
        const cardListHistory = data.cardListHistory;
        setResultList(cardListHistory);
        const retrieved = retrieveSpecificStatus(cardListHistory, selectedTag);
        setFilteredResultList(retrieved);
      } catch (error) {
        console.error("getCardListHistory error occurred: ", error);
      }
    };
    fetchCardListHistory();
    setNeedToUpdateCardList(false);
  }, [needToUpdateCardList]);

  useEffect(() => {
    const totalValue = selectedItems.reduce((acc, currentItem) => {
      return acc + Number(currentItem.value);
    }, 0);
    setTotalValOfselectedItems(totalValue);
  }, [selectedItems]);

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((i) => i !== item);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  const selectAllCard = () => {
    setSelectedItems(filteredResultList);
  };

  const removeSelectedCard = () => {
    const tmp = filteredResultList.filter((item) => {
      return !selectedItems.some((selectedItem) => {
        return item.id === selectedItem.id;
      });
    });
    setFilteredResultList(tmp);
    setSelectedItems([]);
  };

  return (
    <div className={clsx("h-screen w-screen background-diagonal-lines")}>
      {/*  */}
      {loading && filteredResultList.length === 0 && <JGSLoaing />}
      {/*  */}
      <GeneralHeader
        pointUpdated={pointUpdated}
        setPointUpdated={setPointUpdated}
      />
      {/*  */}
      <div
        className="h-full w-full max-w-[768px] mx-auto overflow-y-scroll bg-white md:pb-0"
        style={{ paddingBottom: "260px" }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* 獲得履歴タイトル部分 */}
        <div className={clsx("px-2 pt-4 pb-2 mt-[80px] md:mt-[112px]")}>
          <BtnBackTransparent destination={"/"} />
        </div>
        <div className={clsx("w-full flex justify-center mb-2")}>
          <h1 className={clsx("font-bold text-[24px]")}>獲得履歴</h1>
        </div>
        {/* Tags */}
        <div className={clsx("w-full flex flex-col items-start")}>
          <div
            className={clsx(
              "w-full gap-2 mt-2 flex justify-center flex-wrap items-center"
            )}
          >
            {tagList.map((tag, index) => (
              <div
                key={index}
                className={clsx(
                  "btn-tag text-[14px]",
                  selectedTag === tag.key ? "btn-tag-active" : "btn-tag-default"
                )}
                onClick={() => setSelectedTag(tag.key)}
              >
                {tag.value}
              </div>
            ))}
          </div>
        </div>
        {/* コンテンツ部分 */}
        <div onClick={(e) => e.stopPropagation()}>
          {selectedTag === "yet" ? (
            <div className={clsx("w-full flex justify-center my-4")}>
              <h2 className={clsx("font-semibold text-[16px]")}>
                発送する商品をお選びください
              </h2>
            </div>
          ) : (
            <div className="my-4"></div>
          )}

          {/* 当選商品 */}
          <div className="flex flex-wrap justify-center gap-3">
            {filteredResultList.map((item, index) => {
              const isChecked = selectedItems.includes(item);
              return (
                <div
                  key={index}
                  className={clsx(
                    "w-full h-full",
                    "border-result-item border-[1px] bg-white",
                    "flex flex-col rounded-xl",
                    { "border-checked": isChecked }
                  )}
                  style={{
                    maxWidth: "174px",
                    maxHeight: "142px",
                    padding: "8px",
                  }}
                >
                  {/* Row-1 */}
                  <div className="flex items-center" style={{ height: "94px" }}>
                    {/* image */}
                    <div
                      style={{ width: "56px", height: "78.22px" }}
                      className="flex items-center"
                    >
                      <img src={item.img} alt={item.name} />
                    </div>
                    {/* text */}
                    <div className="flex flex-col justify-between items-end ml-2">
                      {selectedTag === "yet" && (
                        <>
                          <input
                            type="checkbox"
                            className="opacity-0 w-0 h-0"
                            id={`checkbox-${index}`}
                            checked={selectedItems.includes(item)}
                            onChange={() => handleCheckboxChange(item)}
                          />
                          <label
                            htmlFor={`checkbox-${index}`}
                            style={{ width: "16.5px", height: "16.5px" }}
                            className="text-white bg-gray-400 mb-2 rounded flex items-center justify-center cursor-pointer"
                          >
                            ✓
                          </label>
                          <style>{`
                            input:checked + label {
                              background-color: #FDE046;
                              color: #000;
                            }
                            .border-checked {
                              border-color: #FDE046 !important;
                              border-width: 2px !important;
                            }
                          `}</style>
                        </>
                      )}
                      <div className="w-full flex justify-start">
                        <p className="text-xs m-0 font-bold">{item.name}</p>
                      </div>
                      <p className="text-xs m-0">{item.rarity}</p>
                      <p className="text-xs m-0 font-bold">
                        {item.pack} {item.serial}
                      </p>
                    </div>
                  </div>
                  {/* 2 row */}
                  <div className="w-full">
                    {(selectedTag === "shipping-request" ||
                      selectedTag === "shipped") && (
                      <div className="w-full flex">
                        <p className={clsx("text-[12px] font-bold")}>
                          追跡番号
                        </p>
                      </div>
                    )}
                    <div
                      className="w-full h-[24px] flex items-center justify-center"
                      style={{
                        backgroundColor: "var(--black-5, #0000000D)",
                        borderRadius: "4px",
                      }}
                    >
                      {selectedTag === "shipping-request" ||
                      selectedTag === "shipped" ? (
                        <p className={clsx("text-[10px] font-bold break-all")}>
                          {item.invoiceNumber ? item.invoiceNumber : "未入力"}
                        </p>
                      ) : (
                        <p className={clsx("font-bold text-[12px]")}>
                          {item.value}Pt
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {selectedTag === "yet" && (
          <HistoryFooter
            footerNum={footerNum}
            setFooterNum={setFooterNum}
            totalValOfselectedItems={totalValOfselectedItems}
            selectAllCard={selectAllCard}
            removeSelectedCard={removeSelectedCard}
            resultList={filteredResultList}
            setResultList={setFilteredResultList}
            selectedItems={selectedItems}
            setNeedToUpdateCardList={setNeedToUpdateCardList}
          />
        )}
      </div>
    </div>
  );
}
