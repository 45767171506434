import React from "react";
import clsx from "clsx";

export function PointConfirm({
  // eslint-disable-next-line no-unused-vars
  setFooterNum,
  totalValue,
  totalNum,
  selectedNum,
  exchangeFunc,
}) {
  // useEffect(() => {
  //   const totalValue = selectedItems.reduce((acc, currentItem) => {
  //     return acc + Number(currentItem.value);
  //   }, 0);
  //   setTotalValOfselectedItems(totalValue);
  // }, [selectedItems]);

  return (
    <div
      className={clsx(
        "w-full h-[182px] md:h-[198px] flex items-center justify-center flex-col"
      )}
    >
      <div
        className={clsx(
          "w-full max-w-[382px] flex flex-col items-center gap-4"
        )}
      >
        {/*  */}
        <div className="flex items-center">
          <button
            className="px-3 py-1 bg-gray-400 text-[12px] hover:bg-jgs-main-hovered text-white rounded-full mr-4"
            onClick={() => setFooterNum(0)}
          >
            やり直す
          </button>
          <p className={clsx("font-bold text-[16px] text-jgs-red")}>
            {selectedNum}/{totalNum}枚を選択中
          </p>
        </div>
        {/*  */}
        <div className="flex-col items-center">
          <p className={clsx("text-[12px] font-[300] text-black")}>
            発送しない商品は全てポイント還元されます
          </p>
          <p className={clsx("text-[14px] font-bold text-black")}>
            還元ポイント
            <span className="text-[24px] mx-2">
              {totalValue}
              <span className={clsx("text-[14px]")}>Pt</span>
            </span>
            <span className="text-[12px] text-gray-500">
              ({selectedNum}/{totalNum}枚分)
            </span>
          </p>
        </div>
        {/*  */}
        <button
          className="w-full bg-black hover:bg-jgs-main-hovered text-yellow-300 font-bold py-2 px-4 rounded-full cursor-pointer text-[14px]"
          onClick={() => {
            exchangeFunc();
          }}
        >
          全てポイントに還元する
        </button>
      </div>
    </div>
  );
}
