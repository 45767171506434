import React from "react";
import clsx from "clsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../package/firebase";
import { useSetAtom, useAtomValue } from "jotai";
import { isOpenDeleteAddressModalState } from "../stores/isOpenDeleteAddressModalState";
import { deleteAddressIndexState } from "../stores/deleteAddressIndex";
import { addressesState } from "../stores/addressesState";
import { deleteAddress } from "../actions/backend";
import { ERROR_MSG_PREFIX } from "../const/error-message";

export function DeleteAddressModal(props) {
  const deleteAddressIndex = useAtomValue(deleteAddressIndexState);
  const isOpen = useAtomValue(isOpenDeleteAddressModalState);
  const [user] = useAuthState(fireauth);

  if (!user || deleteAddressIndex === -1) return <></>;
  return (
    <div {...props}>
      {isOpen && (
        <Overlay>
          <Background>
            <div className={clsx("font-bold", "mb-4")}>
              住所を削除してよろしいですか？
            </div>
            <ConfirmButton index={deleteAddressIndex} />
          </Background>
        </Overlay>
      )}
    </div>
  );
}

const Overlay = (props) => {
  const setIsOpen = useSetAtom(isOpenDeleteAddressModalState);

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={clsx(
        "fixed top-0 left-0",
        "w-[100%] h-[100%]",
        "bg-black/50",
        "flex justify-end items-center"
      )}
      onClick={closeMenu}
      {...props}
    />
  );
};

const Background = (props) => {
  return (
    <div
      className={clsx(
        "w-[400px] px-4 py-8 rounded-xl",
        "bg-white shadow-[-2px_0_5px_rgba(0,0,0,0.1)]",
        "flex flex-col",
        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      )}
      onClick={(e) => e.stopPropagation()}
      {...props}
    />
  );
};

const ConfirmButton = (props) => {
  const { className, index, ...otherProps } = props;
  const setAddresses = useSetAtom(addressesState);
  const setIsOpen = useSetAtom(isOpenDeleteAddressModalState);

  const handleClick = async () => {
    try {
      await deleteAddress(index);
    } catch (error) {
      console.error(error);
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
    setAddresses((prev) => {
      if (prev.length === 1) return prev;
      const newAddresses = [...prev];
      newAddresses.splice(index, 1);
      let i;
      for (i = 0; i < newAddresses.length; i++) {
        if (newAddresses[i].isSelected) break;
      }
      if (i === newAddresses.length)
        newAddresses[newAddresses.length - 1].isSelected = true;
      return newAddresses;
    });
    setIsOpen(false);
  };

  return (
    <button
      className={clsx(
        className,
        "w-[100%]",
        "px-4 py-2",
        "border border-blue-500",
        "text-blue-500 font-bold",
        "rounded-full",
        "bg-transparent",
        "hover:text-white hover:border-transparent hover:bg-blue-700"
      )}
      onClick={handleClick}
      {...otherProps}
    >
      OK
    </button>
  );
};
