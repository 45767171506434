import React, { useEffect, useState } from "react";
import { login } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../actions/auth";
import { useAtom } from "jotai";
import clsx from "clsx";
import { isOpenMyPageState } from "../stores/isOpenMyPageState";
import { BiUserCircle, BiPlusCircle } from "react-icons/bi";
import logoJGS from "../assets/JGS_logo1.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../package/firebase";
import { Spinner } from "./spinner";
import { getPoint } from "../actions/point";
import { ERROR_MSG_PREFIX } from "../const/error-message";
import { MyPageSideBar } from "./my-page-side-bar";
import BtnBack from "./btn-back";
// import sampleEvent from "../assets/sample-event.jpg";

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx("cursor-pointer", "text-2xl")}
      onClick={() => {
        navigate("/");
      }}
    >
      <img src={logoJGS} className={clsx("w-10 h-10 mr-2")} alt="logo" />
    </div>
  );
};

const Point = ({ point }) => {
  const navigate = useNavigate();
  return (
    <button
      className={clsx(
        "mr-2",
        "text-white font-bold bg-jgs-point-amount",
        "rounded-full",
        "pl-4 pr-1",
        "flex items-center",
        "h-[36px] md:h-[40px]"
      )}
      onClick={() => {
        navigate("/point-purchase-list");
      }}
    >
      {point} pt
      <span className={clsx("ml-2", "text-black font-bold")}>
        <BiPlusCircle
          className={clsx("w-[24px] h-[24px]", "text-jgs-btn-point-add")}
        />
      </span>
    </button>
  );
};

const UserIcon = () => {
  const [isOpenMyPage, setIsOpenMyPage] = useAtom(isOpenMyPageState);
  const toggleMenu = () => {
    setIsOpenMyPage(!isOpenMyPage);
  };

  return (
    <button onClick={toggleMenu}>
      <BiUserCircle
        className={clsx(
          "w-[24px] md:w-[36px] h-[24px] md:h-[36px]",
          "text-white rounded-full"
        )}
      />
    </button>
  );
};

const LoginButton = () => {
  const handleClick = async () => {
    try {
      await login();
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
  };

  return (
    <button
      className={clsx(
        "min-w-[150px]",
        "px-2 py-1",
        "bg-transparent",
        "rounded-md",
        "border border-black",
        "text-black font-bold bg-white",
        "hover:text-gray-100 hover:border-gray-100"
      )}
      onClick={handleClick}
    >
      会員登録/ログイン
    </button>
  );
};

export function GeneralHeader({ pointUpdated, setPointUpdated }) {
  const [user, loading] = useAuthState(fireauth);
  const [point, setPoint] = useState(0);

  useEffect(() => {
    if (!pointUpdated) return;
    const fetchPoint = async () => {
      if (!user) return;
      try {
        const tmp = await getPoint(user.uid);
        if (tmp) setPoint(tmp);
      } catch (error) {
        console.error("getPoint error occurred: ", error);
      }
      setPointUpdated(false);
    };
    fetchPoint();
  }, [pointUpdated]);

  useEffect(() => {
    async function getProfile() {
      const user = await getCurrentUser();
      // const point = await getPoint();
      if (user === null) {
        return;
      }
      // setUser(user);
    }
    const fetchPoint = async () => {
      if (!user) return;
      try {
        const tmp = await getPoint(user.uid);
        if (tmp) setPoint(tmp);
      } catch (error) {
        console.error("getPoint error occurred: ", error);
      }
    };
    getProfile();
    fetchPoint();
  }, []);

  useEffect(() => {
    const fetchPoint = async () => {
      if (!user) return;
      try {
        const pointOfCurrentUser = await getPoint(user.uid);
        if (pointOfCurrentUser) setPoint(pointOfCurrentUser);
      } catch (error) {
        console.error("getPoint error occurred: ", error);
      }
    };
    fetchPoint();
  }, [user]);

  return (
    <header
      className={clsx(
        "h-[80px] md:h-[112px]",
        "w-full flex items-center",
        "fixed",
        "z-30",
        "bg-jgs-main",
        "shadow-detail-header"
      )}
    >
      <div
        className={clsx(
          "max-w-[768px] w-full mx-auto flex justify-between items-center"
        )}
      >
        <div className={clsx("flex items-center")}>
          <Logo />
          <BtnBack destination="/" />
        </div>
        <div className={clsx("flex items-center")}>
          {loading ? (
            <Spinner className={clsx("w-4 h-4 border-[2px]")} />
          ) : (
            <>
              {user && <Point point={point} />}
              {user ? <UserIcon /> : <LoginButton />}
            </>
          )}
        </div>
      </div>
      <MyPageSideBar />
    </header>
  );
}
