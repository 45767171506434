import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../../package/firebase";
import btnGacha from "../../assets/btn-gacha.png";
import btnGacha10 from "../../assets/btn-gacha10.png";
import { MdKeyboardArrowLeft } from "react-icons/md";
import rankOne from "../../assets/rank-one.png";
import rankTwo from "../../assets/rank-two.png";
import rankThree from "../../assets/rank-three.png";
import rankFour from "../../assets/rank-four.png";

const Preview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(fireauth);
  const [groupByRank, setGroupByRank] = useState([]);

  useEffect(() => {
    if (!location.state) {
      alert("不正なアクセスです。");
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.error("Authentication error:", error);
      return;
    }
    if (!user) {
      alert("ログインしていません");
      navigate("/");
    }
  }, [user, loading, error]);

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      const cardList = location.state.cardList;
      const groupByRank = cardList.reduce((acc, item) => {
        if (!acc[item.rank]) {
          acc[item.rank] = [];
        }
        acc[item.rank].push(item);
        return acc;
      }, {});
      const uniqueGroupByRank = Object.keys(groupByRank).reduce((acc, rank) => {
        const uniqueItems = groupByRank[rank].filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.name === item.name)
        );
        acc[rank] = uniqueItems;
        return acc;
      }, {});
      setGroupByRank(uniqueGroupByRank);
    } catch (err) {
      console.error(err);
    }
  }, [location.state]);

  function ProgressBar() {
    return (
      <div className="relative pt-1">
        <div className="overflow-hidden h-2 mt-2 mb-1 text-xs flex rounded bg-gray-200">
          <div
            style={{ width: `${100}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-jgs-main"
          ></div>
        </div>
        <div className=" flex items-center justify-center">
          <span className=" text-lg font-semibold inline-block text-jgs-main">
            のこり {location.state.cardList.length} /{" "}
            {location.state.cardList.length}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-full">
      {/*  */}
      <div className={clsx("flex flex-col", "max-w-[800px] mx-auto")}>
        {/*  */}
        <img
          src={location.state.imageUrl}
          className={clsx(
            "h-screen fixed top-0 left-1/2 transform -translate-x-1/2 z-10"
          )}
          alt="eyecatch image"
        />
        <div className="z-30 ml-4 mt-4">
          <button
            className={clsx("btn-go-back flex")}
            onClick={() => {
              navigate("/admin");
            }}
          >
            <MdKeyboardArrowLeft className={clsx("w-5 h-5")} /> 戻る
          </button>
        </div>
        {/*  */}
        <div
          className={clsx(
            "pb-4 fixed bottom-0 left-1/2 transform -translate-x-1/2 bg-white z-30"
          )}
        >
          <div className={clsx("w-screen px-6 flex flex-col items-center")}>
            <div
              style={{ minWidth: "40%" }}
              className={clsx(
                "max-w-[800px] mx-auto flex flex-col items-center"
              )}
            >
              <button disabled className="btn-price">
                {location.state.price} pt
              </button>
              <ProgressBar />
            </div>
            <div className="flex justify-center items-center gap-8 flex-wrap">
              <img
                src={btnGacha}
                className={clsx("w-36")}
                alt="ガチャる"
                onClick={async () => {}}
              />
              <img
                src={btnGacha10}
                className={clsx("w-36")}
                alt="10連ガチャる"
                onClick={async () => {}}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div
          style={{ marginTop: "100vh" }}
          className={clsx(
            "min-h-screen p-4 pb-56 gap-4 flex flex-col items-center bg-white z-20"
          )}
        >
          <img src={rankOne} className={clsx("w-1/2")} alt="rank one" />
          <div
            className={clsx(
              "w-full my-2 overflow-x-auto whitespace-nowrap",
              {
                "grid grid-rows-2 grid-flow-col gap-4":
                  groupByRank && groupByRank[1] && groupByRank[1].length >= 2,
              },
              {
                "flex gap-4": !(
                  groupByRank &&
                  groupByRank[1] &&
                  groupByRank[1].length >= 2
                ),
              }
            )}
          >
            {groupByRank &&
              groupByRank[1] &&
              groupByRank[1].map((item, index) => (
                <div
                  key={index}
                  className="w-24 font-bold rounded-xl flex-none"
                >
                  <img src={item.img} alt="rank1" />
                </div>
              ))}
          </div>
          <img src={rankTwo} className={clsx("w-1/2")} alt="rank two" />
          <div
            className={clsx(
              "w-full my-2 overflow-x-auto whitespace-nowrap",
              {
                "grid grid-rows-2 grid-flow-col gap-4":
                  groupByRank && groupByRank[2] && groupByRank[2].length >= 2,
              },
              {
                "flex gap-4": !(
                  groupByRank &&
                  groupByRank[2] &&
                  groupByRank[2].length >= 2
                ),
              }
            )}
          >
            {groupByRank &&
              groupByRank[2] &&
              groupByRank[2].map((item, index) => (
                <div
                  key={index}
                  className="w-24 font-bold rounded-xl flex-none"
                >
                  <img src={item.img} alt="rank2" />
                </div>
              ))}
          </div>
          <img src={rankThree} className={clsx("w-1/2")} alt="rank three" />
          <div
            className={clsx(
              "w-full my-2 overflow-x-auto whitespace-nowrap",
              {
                "grid grid-rows-2 grid-flow-col gap-4":
                  groupByRank && groupByRank[3] && groupByRank[3].length >= 2,
              },
              {
                "flex gap-4": !(
                  groupByRank &&
                  groupByRank[3] &&
                  groupByRank[3].length >= 2
                ),
              }
            )}
          >
            {groupByRank &&
              groupByRank[3] &&
              groupByRank[3].map((item, index) => (
                <div
                  key={index}
                  className="w-24 font-bold rounded-xl flex-none"
                >
                  <img src={item.img} alt="rank3" />
                </div>
              ))}
          </div>
          <img src={rankFour} className={clsx("w-1/2")} alt="rank four" />
          <div
            className={clsx(
              "w-full my-2 overflow-x-auto whitespace-nowrap",
              {
                "grid grid-rows-2 grid-flow-col gap-4":
                  groupByRank && groupByRank[4] && groupByRank[4].length >= 2,
              },
              {
                "flex gap-4": !(
                  groupByRank &&
                  groupByRank[4] &&
                  groupByRank[4].length >= 2
                ),
              }
            )}
          >
            {groupByRank &&
              groupByRank[4] &&
              groupByRank[4].map((item, index) => (
                <div
                  key={index}
                  className="w-24 font-bold rounded-xl flex-none"
                >
                  <img src={item.img} alt="rank4" />
                </div>
              ))}
          </div>

          {/* <p className={clsx("mt-4")}>中には傷が含まれるものもございます。</p> */}
        </div>
        {/*  */}
      </div>
    </div>
  );
};

export default Preview;
