import React from "react";
import clsx from "clsx";
import { login } from "../../actions/auth";
import { ERROR_MSG_PREFIX } from "../../const/error-message";

export function LoginButton() {
  const handleClick = async () => {
    try {
      await login();
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
  };

  return (
    <button
      className={clsx(
        "min-w-[150px]",
        "px-2 py-1",
        "bg-transparent",
        "rounded-md",
        "border border-black",
        "text-black font-bold bg-white",
        "hover:text-gray-100 hover:border-gray-100"
      )}
      onClick={handleClick}
    >
      会員登録/ログイン
    </button>
  );
}
