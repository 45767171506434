export const retrieveSpecificStatus = (historyList, targetStatus) => {
  return historyList.filter((history) => history.status === targetStatus);
};

// 所有済みカード数を返す
export const retrieveHeldCard = (historyList) => {
  return historyList.filter((history) => history.holderId !== "");
};

export const displayJaStatus = (holderId) => {
  switch (holderId) {
    case "":
      return "未当選";
    case false:
      return "未当選";
    case true:
      return "当選済み";
    default:
      return "当選済み";
  }
};

export const displayJaStatusForWithdraw = (status) => {
  switch (status) {
    case "yet":
      return "-";
    case "exchanged":
      return "ポイント還元";
    case "shipping-request":
      return "未発送";
    case "shipped":
      return "発送済み";
    default:
      return "";
  }
};

export const transformData = (rawData) => {
  if (!rawData) return null;
  // 変換後のフォーマットの配列を生成する
  const transformed = [];

  rawData.forEach((item) => {
    const stock = parseInt(item[9], 10); // 在庫の数を整数に変換

    if (!isNaN(stock)) {
      for (let i = 0; i < stock; i++) {
        transformed.push({
          PSA: item[1],
          img: item[2], // cardImgとして使用される
          name: item[3], // cardNameとして使用される
          pack: item[4],
          rank: item[5],
          rarity: item[6],
          serial: item[7],
          value: item[8],
          // item[0]とitem[9]は無視する
        });
      }
    } else {
      transformed.push({
        PSA: item[1],
        img: item[2], // cardImgとして使用される
        name: item[3], // cardNameとして使用される
        pack: item[4],
        rank: item[5],
        rarity: item[6],
        serial: item[7],
        value: item[8],
        // item[0]とitem[9]は無視する
      });
    }
  });

  return transformed;
};
