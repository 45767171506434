import React, { useEffect } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { AddressRadioBox } from "../../components/address-radio-box";
import { DeleteAddressModal } from "../../components/delete-address-modal";
import { addressesState } from "../../stores/addressesState";
import { useAtom } from "jotai";

export function Address() {
  // eslint-disable-next-line
  const [addresses, setAddresses] = useAtom(addressesState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const BackButton = (props) => {
    const { className, ...otherProps } = props;
    const navigate = useNavigate();
    const handleClick = () => {
      if (localStorage.getItem("rootPage")) {
        const key = localStorage.getItem("rootPage");
        const id = localStorage.getItem("detailId")
          ? localStorage.getItem("detailId")
          : null;
        switch (key) {
          case "detail":
            navigate(`/roulette/${id}`);
            break;
          case "history":
            navigate("/my-page/history");
            break;
          default:
            navigate("/");
            break;
        }
      } else {
        navigate("/");
      }
    };

    return (
      <button
        className={clsx(className, "")}
        onClick={() => handleClick()}
        {...otherProps}
      >
        <MdKeyboardArrowLeft className={clsx("w-6 h-6")} />
      </button>
    );
  };

  return (
    <>
      <div
        className={clsx(
          "flex justify-between items-center",
          "border-b-[1px] p-2"
        )}
      >
        <BackButton />
        <div className={clsx("font-bold")}>商品配送先住所の設定</div>
        <div></div>
      </div>
      <AddressRadioBox />
      {addresses.length <= 0 && (
        <div className={clsx("p-2", "mt-4")}>
          <AddAddressPageNavigationButton className={clsx("mb-8")} />
        </div>
      )}
      <DeleteAddressModal />
    </>
  );
}

const AddAddressPageNavigationButton = (props) => {
  const { className, ...otherProps } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem("navigateFromAddOrEdit", JSON.stringify(true));
    navigate("/my-page/add-address");
  };

  return (
    <button
      className={clsx(
        className,
        "w-[100%] max-w-[420px] px-4 py-2 rounded-full border border-blue-500 bg-transparent",
        "text-blue-500 font-bold",
        "hover:text-white hover:border-transparent hover:bg-blue-700"
      )}
      onClick={handleClick}
      {...otherProps}
    >
      + 住所を追加
    </button>
  );
};
