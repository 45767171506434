import React from "react";

const Mask = ({ closeModal, children }) => {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40"
      onClick={closeModal}
    >
      {children}
    </div>
  );
};

export default Mask;
