import { loadStripe } from "@stripe/stripe-js";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { StripePaymentForm } from "./stripePaymentForm";
import { useParams, useNavigate } from "react-router-dom";
import { OurSimplifiedColoredHeader } from "../../components/header-simple-color";
import { createPaymentIntent } from "../../actions/point";
import { Elements } from "@stripe/react-stripe-js";

export function MyPayment() {
  const hasAPICalledRef = useRef(false);
  const [piClientSecret, setPiClientSecret] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (hasAPICalledRef.current) return;
    hasAPICalledRef.current = true;
    createPaymentIntent(params.price)
      .then(({ clientSecret }) => {
        setPiClientSecret(clientSecret);
      })
      .catch((error) => {
        alert("不正なリロードです。トップページに戻ります。 " + error);
        navigate("/");
      });
  }, []);

  function Modal() {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        onClick={() => setOpenModal(false)} // マスク部分をクリックした時にモーダルを閉じる
      >
        <div
          className="bg-white rounded-xl max-w-xl w-4/5"
          onClick={(e) => e.stopPropagation()} // モーダル自体のクリックイベントを親要素に伝播させない
        >
          {/* タイトルセクション */}
          <div className="p-6">
            <h2 className="text-xl font-bold">✅</h2>
          </div>

          {/* 説明セクション */}
          <div className="px-6 py-4">
            <h2>決済が完了しました。</h2>
            <p>ご購入ありがとうございます。</p>
          </div>

          {/* ボタンセクション */}
          <div className="px-6 py-4 flex flex-col items-center gap-4">
            <button
              className="w-56 bg-gray-400 hover:bg-jgs-main-hovered text-black font-bold py-2 px-4 rounded-full"
              onClick={() => {
                setOpenModal(false);
                navigate("/");
              }}
            >
              トップページに戻る
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!piClientSecret) return null;
  return (
    <div className="h-screen w-screen">
      <OurSimplifiedColoredHeader />
      <div className="flex flex-col max-w-[800px] mx-auto p-2 md:px-1">
        <div className={clsx("w-full flex")}>
          <button
            className={clsx(
              "bg-gray-400 text-white font-bold py-0 px-1 rounded-full"
            )}
            onClick={() => handleGoBack()}
          >
            ＜ 戻る
          </button>
        </div>
        <h1 className="text-2xl font-bold text-center mt-4">決済画面</h1>
        <Elements
          stripe={loadStripe(stripeKey)}
          options={{
            clientSecret: piClientSecret,
          }}
        >
          {paymentStatus && openModal ? (
            <Modal />
          ) : (
            <StripePaymentForm
              setPaymentStatus={setPaymentStatus}
              setOpenModal={setOpenModal}
            />
          )}
        </Elements>
      </div>
    </div>
  );
}
