import React, { useEffect, useState } from "react";
import { logout } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { fireauth } from "../package/firebase";
import { useSetAtom, useAtomValue } from "jotai";
import { isOpenMyPageState } from "../stores/isOpenMyPageState";
import { addressesState } from "../stores/addressesState";
import { AiOutlineClose } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { displayPostalCode, getSelectedAddress } from "../utils/utils";
import { ERROR_MSG_PREFIX } from "../const/error-message";
import { isAdmin } from "../actions/user";
import { getPoint } from "../actions/point";
import { RiMoneyDollarCircleFill } from "react-icons/ri";

export function MyPageSideBar(props) {
  const isOpenMyPage = useAtomValue(isOpenMyPageState);
  const [user] = useAuthState(fireauth);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [point, setPoint] = useState(0);

  useEffect(() => {
    const fetchPoint = async () => {
      if (!user) return;
      try {
        const pointOfCurrentUser = await getPoint(user.uid);
        if (pointOfCurrentUser) setPoint(pointOfCurrentUser);
      } catch (error) {
        console.error("getPoint error occurred: ", error);
      }
    };
    const adminChecker = async () => {
      const result = await isAdmin();
      setIsAdminUser(result);
    };
    fetchPoint();
    adminChecker();
  }, [user]);

  if (!user) return <></>;
  return (
    <div {...props}>
      {isOpenMyPage && (
        <Overlay>
          <Background>
            <div>
              <CloseButton />
              <div
                className={clsx(
                  "text-start text-xl font-bold",
                  "px-6 py-4",
                  "border-b-[1px]"
                )}
              >
                マイページ
              </div>
              <div>
                <div
                  className={clsx("flex flex-col items-center", "px-6 py-4")}
                >
                  <div className={clsx("text-start w-[100%] pl-2")}>
                    所持ポイント
                  </div>
                  {user && (
                    <div className={clsx("mb-2 text-2xl font-bold")}>
                      <RiMoneyDollarCircleFill
                        className={clsx(
                          "inline-block mr-1 mb-0.5",
                          "text-yellow-400"
                        )}
                      />
                      {point} <span className={clsx("text-xl")}>points</span>
                    </div>
                  )}
                  <BuyingPointButton className={clsx("w-[100%]", "mb-8")} />
                  <div className={clsx("w-[100%]", "mb-8")}>
                    <div className={clsx("text-start", "mb-1 pl-2")}>
                      郵送先の変更
                    </div>
                    <EditingAddressButton />
                  </div>
                  <BtnHistory className={clsx("w-[100%]", "mb-2")} />
                  {isAdminUser && isAdminUser === true && (
                    <BtnAdmin className={clsx("w-[100%]")} />
                  )}
                  <LogoutButton className={clsx("w-[100%]", "mt-8")} />
                </div>
              </div>
            </div>
            <div
              className={clsx(
                "px-6 py-4 w-[100%]",
                "text-xs text-gray-500 px-6",
                "flex flex-col items-start"
              )}
            >
              <div className={clsx("mb-2 pl-2")}>
                このポイントはJGSでのみご利用可能です。
              </div>
              <a
                href="https://jgs-yokohama.com/company/"
                target="_blank"
                rel="noreferrer"
                className={clsx("mb-1 pl-2", "hover:underline", "inline")}
              >
                利用規約
              </a>
              <a
                href="https://jgs-yokohama.com/company/"
                target="_blank"
                rel="noreferrer"
                className={clsx("pl-2", "hover:underline")}
              >
                特定商取引法に基づく表記
              </a>
            </div>
          </Background>
        </Overlay>
      )}
    </div>
  );
}

const Overlay = (props) => {
  const setIsOpenMyPage = useSetAtom(isOpenMyPageState);

  const closeMenu = () => {
    setIsOpenMyPage(false);
  };

  return (
    <div
      className={clsx(
        "fixed top-0 left-0",
        "w-[100%] h-[100%]",
        "bg-black/50",
        "flex justify-end items-center"
      )}
      onClick={closeMenu}
      {...props}
    />
  );
};

const Background = (props) => {
  return (
    <div
      className={clsx(
        "w-[320px] h-[100%]",
        "bg-white shadow-[-2px_0_5px_rgba(0,0,0,0.1)]",
        "flex flex-col justify-between",
        "relative"
      )}
      onClick={(e) => e.stopPropagation()}
      {...props}
    />
  );
};

const CloseButton = (props) => {
  const setIsOpenMyPage = useSetAtom(isOpenMyPageState);

  const closeMenu = () => {
    setIsOpenMyPage(false);
  };

  return (
    <button
      className={clsx("absolute top-2 right-2", "bg-gray-100 rounded-md p-2")}
      onClick={closeMenu}
      {...props}
    >
      <AiOutlineClose className={clsx("w-4 h-4")} />
    </button>
  );
};

const PointButton = (props) => {
  const { className, children, ...otherProps } = props;

  return (
    <button className={clsx("btn-point", className)} {...otherProps}>
      {children}
    </button>
  );
};

const BuyingPointButton = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/point-purchase-list");
  };

  return (
    <PointButton onClick={handleClick} {...props}>
      ポイントを購入
    </PointButton>
  );
};

const EditingAddressButton = (props) => {
  const { className, ...otherProps } = props;
  const addresses = useAtomValue(addressesState);
  const navigate = useNavigate();
  const address = getSelectedAddress(addresses);

  const handleClick = () => {
    navigate("/my-page/address");
  };

  return (
    <button
      className={clsx(
        className,
        "flex justify-between items-center",
        "border-[1px] border-gray-300 px-4 py-6 w-[100%] rounded-xl"
      )}
      onClick={handleClick}
      {...otherProps}
    >
      {!address ? (
        <div className={clsx("text-gray-700")}>郵送先を登録してください</div>
      ) : (
        <div className={clsx("text-start")}>
          <div
            className={clsx("mb-1")}
          >{`${address.lastName} ${address.firstName}`}</div>
          <div className={clsx("text-xs text-gray-500")}>
            <div>{displayPostalCode(address.postalCode)}</div>
            <div>{`${address.prefecture}${address.addressDesc}`}</div>
          </div>
        </div>
      )}
      <MdKeyboardArrowRight
        className={clsx("w-10 h-10", "text-gray-500", "pl-2")}
      />
    </button>
  );
};

const BtnHistory = (props) => {
  const navigate = useNavigate();
  const { className, ...otherProps } = props;

  const handleClick = () => {
    navigate("/my-page/history");
  };

  return (
    <button
      className={clsx("btn-primary", className)}
      onClick={handleClick}
      {...otherProps}
    >
      獲得履歴
    </button>
  );
};

const BtnAdmin = (props) => {
  const navigate = useNavigate();
  const { className, ...otherProps } = props;
  const handleClick = () => {
    navigate("/admin");
  };

  return (
    <button
      className={clsx("btn-primary", className)}
      onClick={handleClick}
      {...otherProps}
    >
      管理画面
    </button>
  );
};

const LogoutButton = (props) => {
  const navigate = useNavigate();
  const setIsOpenMyPage = useSetAtom(isOpenMyPageState);

  const handleClick = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
    setIsOpenMyPage(false);
  };

  return (
    <PointButton onClick={handleClick} {...props}>
      ログアウト
    </PointButton>
  );
};
