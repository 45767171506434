import React from "react";
import clsx from "clsx";

// eslint-disable-next-line
const ModalCard = ({ closeModal, children }) => {
  return (
    <div
      className={clsx(
        "w-5/6 px-4 py-8 rounded-xl",
        "bg-white shadow-[-2px_0_5px_rgba(0,0,0,0.1)]",
        "flex flex-col",
        "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
};

export default ModalCard;
