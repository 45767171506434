import { fireauth, firebaseFirestore } from "../package/firebase";
import {
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { signInWithPopup, GoogleAuthProvider, getAuth } from "firebase/auth";
import { signOut } from "firebase/auth";
import { getUserByUid } from "./user";
import { FAILED_LOGIN_MSG } from "../const/error-message";

export async function login() {
  try {
    localStorage.removeItem("newPoint");
  } catch (error) {
    console.warn("localStorage is already empty");
  }
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/youtube.readonly");
  const res = await signInWithPopup(fireauth, provider);
  if (!res) throw new Error(FAILED_LOGIN_MSG);
}

export async function loginAndYoutube() {
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/youtube.readonly");
  let result = [];
  let img = "";

  try {
    const res = await signInWithPopup(fireauth, provider);
    if (res) {
      const token = res._tokenResponse.oauthAccessToken;
      const response = await fetch(
        "https://www.googleapis.com/youtube/v3/channels?part=statistics&mine=true",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      let ytSubscriberCount = null;
      if (data.items && data.items.length > 0) {
        ytSubscriberCount = data.items[0].statistics.subscriberCount;
        localStorage.setItem("count", ytSubscriberCount);
      }
      img = res.user.photoURL;

      let userDoc = null;
      try {
        userDoc = await getUserByUid(res.user.uid);
      } catch (error) {
        console.warn(
          "ユーザードキュメントの取得に失敗しましたが、デフォルト値で処理を続行します。",
          error
        );
      }

      const isNotNeededToAddPoint =
        userDoc?.isRegisteredSubscriberCount ?? false;
      let addingPoint = null;
      let newPoint = null;

      if (isNotNeededToAddPoint === false) {
        const standardOfAdding = parseInt(
          process.env.REACT_APP_CAMPAIGN_POINT_THRESHOLD,
          10
        );
        addingPoint =
          parseInt(ytSubscriberCount, 10) < standardOfAdding ? 0 : 5000;
        newPoint = (
          addingPoint + parseInt(userDoc?.point || "0", 10)
        ).toString();
      }
      localStorage.setItem("newPoint", newPoint);
      result = [
        true,
        res.user,
        img,
        ytSubscriberCount,
        isNotNeededToAddPoint,
        addingPoint,
        newPoint,
      ];
    } else {
      result = ["error", "ログインに失敗しました"];
    }
  } catch (error) {
    console.error("ログイン処理中にエラーが発生しました", error);
    result = ["error", "ログイン処理中にエラーが発生しました"];
  }
  return result;
}

export async function logout() {
  await signOut(fireauth);
}

export async function isExistUser(user) {
  const docRef = doc(firebaseFirestore, "users", user.uid);
  const isExist = (await getDoc(docRef)).exists();
  return isExist;
}

export async function getCurrentUser() {
  const user = getAuth().currentUser;
  return user;
}

export async function checkUserPoint() {
  // youtubeの登録者数を取得する関数
  return null;
}

export async function checkUsersItems(user) {
  // userの所持アイテムを取得する関数
  const contents = query(
    collectionGroup(firebaseFirestore, "contents"),
    where("holderId", "==", user.uid)
  );
  const querySnapshot = await getDocs(contents);
  let items = [];
  querySnapshot.forEach((doc) => {
    items.push(doc.data());
  });
  return items;
}
