export const displayPostalCode = (postalCode) => {
  return `〒${postalCode.slice(0, 3)}-${postalCode.slice(3)}`;
};

export const setAllIsSelectedFalse = (addresses) => {
  return addresses.map((address) => ({ ...address, isSelected: false }));
};

export const getSelectedAddressIndex = (addresses) => {
  for (let i = 0; i < addresses.length; i++) {
    if (addresses[i].isSelected) {
      return i;
    }
  }
  return -1;
};

export const getSelectedAddress = (addresses) => {
  for (let i = 0; i < addresses.length; i++) {
    if (addresses[i].isSelected) {
      return addresses[i];
    }
  }
  return undefined;
};

export const initAddress = () => {
  return {
    firstName: "",
    lastName: "",
    firstNameKana: "",
    lastNameKana: "",
    postalCode: "",
    prefecture: "",
    addressDesc: "",
    phoneNumber: "",
    isSelected: true,
  };
};

export const canAddAddress = (address) => {
  return (
    address.firstName !== "" &&
    address.lastName !== "" &&
    address.firstNameKana !== "" &&
    address.lastNameKana !== "" &&
    address.postalCode !== "" &&
    address.prefecture !== "" &&
    address.addressDesc !== "" &&
    address.phoneNumber !== ""
  );
};

export const findHighestRank = (objects) => {
  if (objects.length === 0) {
    return null; // 配列が空の場合はnullを返す
  }

  let highestRank = objects[0].rank;

  for (let i = 0; i < objects.length; i++) {
    if (objects[i].rank < highestRank) {
      highestRank = objects[i].rank;
    }
  }

  return highestRank;
};
