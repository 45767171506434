import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

let firebaseConfig = {};
if (process.env.REACT_APP_STAGE === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCEbOfqDnwX1CFOrHTYulenQES-CjZDLdQ",
    authDomain: "roulette-app-70303.firebaseapp.com",
    projectId: "roulette-app-70303",
    storageBucket: "roulette-app-70303.appspot.com",
    messagingSenderId: "40194487300",
    appId: "1:40194487300:web:b851d959f1682a88847151",
    measurementId: "G-5YKMMHRGZN",
  };
} else if (process.env.REACT_APP_STAGE === "staging") {
  firebaseConfig = {
    apiKey: "AIzaSyBXMdofocuQjF4hEQyOCO19jQCElpuG-fo",
    authDomain: "roulette-app-staging.firebaseapp.com",
    projectId: "roulette-app-staging",
    storageBucket: "roulette-app-staging.appspot.com",
    messagingSenderId: "496952883121",
    appId: "1:496952883121:web:5059246e861eaa46faaf90",
    measurementId: "G-YVRFN4DMKZ",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyDC2AuOd4nQ94gRj8wnj0MurKJT02qtWt0",
    authDomain: "roulette-app-dev.firebaseapp.com",
    projectId: "roulette-app-dev",
    storageBucket: "roulette-app-dev.appspot.com",
    messagingSenderId: "867230818310",
    appId: "1:867230818310:web:410dcec2921eee77c6a387",
    measurementId: "G-9ZMES7Q8RK",
  };
}
const app = initializeApp(firebaseConfig);

export const firebaseFirestore = getFirestore(app);
export const fireauth = getAuth(app);
export const storage = getStorage(app);
