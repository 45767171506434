import React from "react";

export function ProgressBar({ numberOfInventory, cardList }) {
  const progress = numberOfInventory / cardList.length / 0.01;
  return (
    <div className="text-white relative pt-1 w-[183px]">
      <div className="flex items-center justify-center">
        <span className="text-[12px] font-semibold inline-block">
          のこり {numberOfInventory} / {cardList.length}
        </span>
      </div>
      <div className="overflow-hidden h-[6px] mt-2 mb-1 text-xs flex rounded bg-gray-200">
        <div
          style={{ width: `${progress}%` }}
          className="border-[1px] border-white shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-jgs-main"
        ></div>
      </div>
    </div>
  );
}
