import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { addressesState } from "../../stores/addressesState";
import { MdKeyboardArrowRight } from "react-icons/md";
import { displayPostalCode, getSelectedAddress } from "../../utils/utils";

export function ShipmentConfirm({
  setFooterNum,
  resultList,
  detailId,
  selectedNum,
  selectedItems,
  shipFunc,
}) {
  const addresses = useAtomValue(addressesState);
  const address = getSelectedAddress(addresses);

  // eslint-disable-next-line no-unused-vars
  const EditingAddressButton = (props) => {
    const { className, ...otherProps } = props;
    const navigate = useNavigate();

    const handleClick = () => {
      localStorage.setItem("resultList", JSON.stringify(resultList));
      if (detailId) {
        localStorage.setItem("rootPage", "detail");
        localStorage.setItem("detailId", detailId);
      } else {
        localStorage.setItem("rootPage", "history");
      }
      navigate("/my-page/address");
    };

    return (
      <button
        className={clsx(
          className,
          "flex justify-between items-center",
          "bg-white border-[1px] border-gray-300 px-4 py-2 w-[100%] rounded-xl"
        )}
        onClick={handleClick}
        {...otherProps}
      >
        {!address ? (
          <div className={clsx("text-gray-700")}>郵送先を登録してください</div>
        ) : (
          <div className={clsx("text-start")}>
            <div
              className={clsx("text-xs text-gray-500")}
            >{`${address.lastName} ${address.firstName}`}</div>
            <div className={clsx("text-xs text-gray-500")}>
              <div
                className={clsx(
                  "w-36 whitespace-nowrap overflow-hidden overflow-ellipsis"
                )}
              >
                {displayPostalCode(address.postalCode)}{" "}
                {`${address.prefecture}${address.addressDesc}`}
              </div>
            </div>
          </div>
        )}
        <MdKeyboardArrowRight
          className={clsx("w-10 h-10", "text-gray-500", "pl-2")}
        />
      </button>
    );
  };

  const unselectedItems = resultList.filter(
    (item) => !selectedItems.includes(item)
  );
  const totalValue = unselectedItems.reduce(
    (sum, item) => parseInt(sum, 10) + parseInt(item.value, 10),
    0
  );
  const unselectedCount = unselectedItems.length;

  return (
    <div
      className={clsx(
        "w-full h-[182px] md:h-[198px] flex items-center justify-center flex-col"
      )}
    >
      <div
        className={clsx(
          "w-full max-w-[382px] flex flex-col items-center gap-4"
        )}
      >
        {/*  */}
        <div className="flex items-center">
          <button
            className="px-3 py-1 bg-gray-400 text-[12px] hover:bg-jgs-main-hovered text-white rounded-full mr-4"
            onClick={() => setFooterNum(0)}
          >
            やり直す
          </button>
          <p className={clsx("font-bold text-[16px] text-jgs-red")}>
            {selectedNum}/{resultList.length}枚を選択中
          </p>
        </div>
        {/*  */}
        <button
          className="w-full h-[46px] bg-jgs-btn-shipment text-black font-bold py-2 px-4 rounded-full cursor-pointer"
          onClick={() => {
            shipFunc();
          }}
        >
          カードを発送依頼
        </button>
        {/*  */}
        <div className="flex-col items-center">
          <p className={clsx("text-[12px]")}>
            発送しない商品は全てポイント還元されます
          </p>
          <p className={clsx("leading-none text-[14px] font-bold text-black")}>
            還元ポイント
            <span className="text-[24px] mx-2">
              {totalValue}
              <span className={clsx("text-[14px]")}>Pt</span>
            </span>
            <span className="text-[12px] text-gray-500">
              ({unselectedCount}/{resultList.length}枚分)
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
