import React from "react";
import clsx from "clsx";
import { PointConfirm } from "../footer/point-confirm.jsx";
import { PointComplete } from "../footer/point-complete.jsx";
import { ShipmentConfirm } from "../footer/shipment-confirm.jsx";
import { ShipmentComplete } from "../footer/shipment-complete.jsx";

export function ResultFooter({
  resultList,
  selectedItems,
  setSelectedItems,
  totalValOfselectedItems,
  footerNum,
  setFooterNum,
  exchangeAction,
  shipmentAction,
  setOpenResultModal,
  id,
  selectAllCard,
}) {
  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((i) => i !== item);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  return (
    <div className={clsx("h-screen w-screen background-diagonal-lines")}>
      <div
        className="h-full w-full max-w-[768px] mx-auto overflow-y-scroll bg-white md:pb-0"
        style={{ paddingBottom: "260px", paddingTop: "24px" }}
        onClick={(e) => e.stopPropagation()}
      >
        {/*  */}
        <div className="pt-6 mt-[80px] md:mt-[112px]">
          <h1 className={clsx("text-[24px] font-bold")}>抽選結果</h1>
        </div>
        {/*  */}
        <div className="mt-2 mb-4">
          <p className={clsx("text-[16px] font-semibold")}>
            発送する商品をお選びください
          </p>
        </div>
        {/* items */}
        <div className="flex flex-wrap justify-center gap-3">
          {resultList.map((item, index) => {
            const isChecked = selectedItems.includes(item);
            return (
              <div
                key={index}
                className={clsx(
                  "w-full h-full",
                  "border-result-item border-[1px] bg-white",
                  "flex flex-col rounded-xl",
                  { "border-checked": isChecked }
                )}
                style={{
                  maxWidth: "174px",
                  maxHeight: "142px",
                  padding: "8px",
                }}
              >
                {/* 1 row */}
                <div className="flex items-center" style={{ height: "94px" }}>
                  {/* image */}
                  <div
                    style={{ width: "56px", height: "78.22px" }}
                    className="flex items-center"
                  >
                    <img src={item.img} alt={`Item${index}`} />
                  </div>
                  {/* text */}
                  <div className="flex flex-col justify-between items-end ml-2">
                    <input
                      type="checkbox"
                      className="opacity-0 w-0 h-0"
                      id={`checkbox-${index}`}
                      checked={selectedItems.includes(item)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                    <label
                      htmlFor={`checkbox-${index}`}
                      style={{ width: "16.5px", height: "16.5px" }}
                      className="text-white bg-gray-400 mb-2 rounded flex items-center justify-center cursor-pointer"
                    >
                      ✓
                    </label>
                    <style>{`
                      input:checked + label {
                        background-color: #FDE046;
                        color: #000;
                      }
                      .border-checked {
                        border-color: #FDE046 !important;
                        border-width: 2px !important;
                      }
                    `}</style>
                    <div className="w-full flex justify-start">
                      <p className="text-xs m-0 font-bold">{item.name}</p>
                    </div>
                    <p className="text-xs m-0">{item.rarity}</p>
                    <p className="text-xs m-0 font-bold">
                      {item.pack} {item.serial}
                    </p>
                  </div>
                </div>
                {/* 2 row */}
                <div className="w-full">
                  <div
                    className="w-full h-[24px] flex items-center justify-center"
                    style={{
                      backgroundColor: "var(--black-5, #0000000D)",
                      borderRadius: "4px",
                    }}
                  >
                    <p className={clsx("font-bold text-[12px]")}>
                      {item.value}Pt
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Footer */}
      <div
        className={clsx(
          "px-4 fixed bottom-0 max-w-[540px] w-full rounded-t-[16px] flex justify-center left-1/2 transform -translate-x-1/2 bg-gray-300 z-50"
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {footerNum === 0 && (
          <div className={clsx("w-full py-[32px] flex flex-col")}>
            <div className="flex justify-between items-center">
              <p className={clsx("font-bold text-[16px] text-jgs-red")}>
                {selectedItems.length}/{resultList.length}枚を選択中
              </p>
              {resultList.length === 0 || selectedItems.length === 0 ? (
                <button
                  className="w-[144px] h-[46px] text-[14px] font-bold py-2 px-4 rounded-full"
                  disabled
                  style={{
                    backgroundColor: "rgba(253, 224, 70, 0.40)",
                    color: "rgba(0, 0, 0, 0.40)",
                  }}
                >
                  カードを発送依頼
                </button>
              ) : (
                <button
                  className="w-[144px] h-[46px] text-[14px] bg-jgs-btn-shipment text-black font-bold py-2 px-4 rounded-full cursor-pointer"
                  onClick={() => {
                    if (selectedItems.length === 0) {
                      alert("発送する商品を選択してください。");
                      return;
                    }
                    if (resultList.length === 0 && selectedItems.length === 0) {
                      alert("発送対象の商品がありません。");
                      setOpenResultModal(false);
                      return;
                    }
                    setFooterNum(3);
                  }}
                >
                  カードを発送依頼
                </button>
              )}
            </div>
            <div
              className="my-4 w-full bg-gray-400"
              style={{ height: "1px" }}
            />
            <div className="flex justify-between items-center">
              <div className="flex flex-col justify-between items-start">
                <p className={clsx("text-[12px] font-[300] text-black")}>
                  発送しない商品は全てポイント還元されます
                </p>
                <p className={clsx("text-[14px] font-bold text-black")}>
                  還元ポイント
                  <span className="text-[24px] mx-2">
                    {totalValOfselectedItems}
                    <span className={clsx("text-[14px]")}>Pt</span>
                  </span>
                  <span className="text-[12px] text-gray-500">
                    ({selectedItems.length}/{resultList.length}枚分)
                  </span>
                </p>
              </div>
              <button
                className="bg-black hover:bg-jgs-main-hovered text-yellow-300 font-bold py-2 px-4 rounded-full"
                style={{
                  fontSize: "14px",
                  maxWidth: "186px",
                  width: "100%",
                }}
                onClick={() => {
                  if (resultList.length === 0) {
                    alert("還元対象の商品がありません。");
                    setOpenResultModal(false);
                    return;
                  }
                  selectAllCard();
                  setFooterNum(1);
                }}
              >
                全てポイントに還元する
              </button>
            </div>

            {/* <div className="flex items-center">
              <div className="w-1/3 flex flex-col items-start">
                <p className={clsx("text-xs text-gray-500 m-0 font-bold")}>
                  還元ポイント
                </p>
                <h1 className={clsx("text-xl font-bold")}>
                  {totalValOfselectedItems} pt
                </h1>
              </div>
              <div className="w-2/3 flex flex-col">
                <div className={clsx("mb-2")}>
                  <div className={clsx("text-start text-xs text-gray-500")}>
                    郵送先
                  </div>
                  <EditingAddressButton
                    resultList={resultList}
                    id={id}
                    className={undefined}
                  />
                </div>
              </div>
            </div> */}
          </div>
        )}
        {footerNum === 1 && (
          <PointConfirm
            setFooterNum={setFooterNum}
            totalValue={totalValOfselectedItems}
            totalNum={resultList.length}
            selectedNum={selectedItems.length}
            exchangeFunc={() => {
              exchangeAction();
            }}
          />
        )}
        {footerNum === 2 && (
          <PointComplete
            setFooterNum={setFooterNum}
            setOpenResultModal={setOpenResultModal}
            setPointUpdated={() => {}}
          />
        )}
        {footerNum === 3 && (
          <ShipmentConfirm
            setFooterNum={setFooterNum}
            resultList={resultList}
            detailId={id}
            selectedItems={selectedItems}
            selectedNum={selectedItems.length}
            shipFunc={() => {
              shipmentAction();
            }}
          />
        )}
        {footerNum === 4 && (
          <ShipmentComplete
            setFooterNum={setFooterNum}
            setOpenResultModal={setOpenResultModal}
          />
        )}
      </div>
    </div>
  );
}
