import React from "react";

export function PrivacyPolicy() {
  return (
    <div className="bg-slate-700">
      <div className="bg-slate-700 text-white min-h-screen mx-auto max-w-[1050px] p-4 flex flex-col text-start items-start justify-start">
        <h1 className="text-xl my-2 font-bold">プライバシーポリシー</h1>

        <br></br>

        <h2 className="text-lg mb-2 font-bold">概要</h2>
        <p>
          本アプリケーション（以下、「当アプリ」といいます）は、YouTube
          Login機能を使用して、ユーザーがYouTubeアカウントでログインできるようにし、登録者数などのYouTubeデータを取得して使用します。当アプリは、Googleアカウントの情報をユーザー識別子として使用します。
        </p>

        <br></br>

        <h2 className="text-lg mb-2 font-bold">ユーザーデータの使用</h2>
        <p>
          当アプリでは、YouTube
          Login機能を通じて取得したユーザーデータを以下の目的で使用します：
        </p>
        <p>- ユーザー識別と認証</p>
        <p>- YouTubeチャンネルの登録者数の取得</p>
        <p>
          -
          ユーザーが自身のYouTubeチャンネルに関する情報を確認できるようにするため
        </p>
        <p>
          ユーザーデータの使用は、本プライバシーポリシーに記載されている目的に限定されます。
        </p>

        <br></br>

        <h2 className="text-lg mb-2 font-bold">データの保存と共有</h2>
        <p>
          当アプリは、取得したユーザーデータを安全に保存し、第三者と共有することはありません。ただし、以下の場合を除きます：
        </p>
        <p>- 法律や規制により開示が求められた場合</p>
        <p>- ユーザーの明示的な同意がある場合</p>

        <br></br>

        <h2 className="text-lg mb-2 font-bold">プライバシーポリシーの変更</h2>
        <p>
          当アプリのプライバシーポリシーは、必要に応じて更新されることがあります。変更があった場合は、当アプリのウェブサイト上で通知し、更新されたプライバシーポリシーを公開します。
        </p>

        <br></br>

        <h2 className="text-lg mb-2 font-bold">お問い合わせ</h2>
        <p>
          プライバシーポリシーに関するご質問や懸念がある場合はご連絡ください。
        </p>

        <br></br>

        <h2 className="text-lg mb-2 font-bold">
          Google API Services User Data Policy
        </h2>

        <p>
          Revolve use and transfer of information received from Google APIs to
          any other app will adhere to{" "}
          <span>
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy?hl=ja#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noopener noreferrer"
              className="mb-1 hover:underline inline"
            >
              Google API Services User Data Policy
            </a>
          </span>
          , including the Limited Use requirements.
        </p>
      </div>
    </div>
  );
}
