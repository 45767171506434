import { getAuth } from "firebase/auth";
import { BACKEND_URL } from "../const/const";
import { NOT_LOGINED_MSG } from "../const/error-message";

export const getCardListHistory = async () => {
  const user = await getAuth().currentUser;
  if (!user) throw new Error(NOT_LOGINED_MSG);
  const token = await user.getIdToken();
  const res = await fetch(`${BACKEND_URL}/card-list-history`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);
  return data;
};
