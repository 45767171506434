import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { PointConfirm } from "../footer/point-confirm";
import { PointComplete } from "../footer/point-complete";
import { ShipmentConfirm } from "../footer/shipment-confirm";
import { ShipmentComplete } from "../footer/shipment-complete";
import { exchange, requestShipment } from "../../actions/backend";
import { ERROR_MSG_PREFIX } from "../../const/error-message";
import { MdKeyboardArrowRight } from "react-icons/md";
import { displayPostalCode, getSelectedAddress } from "../../utils/utils";
import { useAtomValue } from "jotai";
import { addressesState } from "../../stores/addressesState";

// eslint-disable-next-line no-unused-vars
const EditingAddressButton = (props) => {
  const { className, ...otherProps } = props;
  const addresses = useAtomValue(addressesState);
  const navigate = useNavigate();
  const address = getSelectedAddress(addresses);

  const handleClick = () => {
    localStorage.setItem("rootPage", "history");
    navigate("/my-page/address");
  };

  return (
    <button
      className={clsx(
        className,
        "flex justify-between items-center",
        "bg-white border-[1px] border-gray-300 px-4 py-2 w-[100%] rounded-xl"
      )}
      onClick={handleClick}
      {...otherProps}
    >
      {!address ? (
        <div className={clsx("text-gray-700")}>郵送先を登録してください</div>
      ) : (
        <div className={clsx("text-start")}>
          <div
            className={clsx("text-xs text-gray-500")}
          >{`${address.lastName} ${address.firstName}`}</div>
          <div className={clsx("text-xs text-gray-500")}>
            <div
              className={clsx(
                "w-36 whitespace-nowrap overflow-hidden overflow-ellipsis"
              )}
            >
              {displayPostalCode(address.postalCode)}{" "}
              {`${address.prefecture}${address.addressDesc}`}
            </div>
          </div>
        </div>
      )}
      <MdKeyboardArrowRight
        className={clsx("w-10 h-10", "text-gray-500", "pl-2")}
      />
    </button>
  );
};

export function HistoryFooter(props) {
  // eslint-disable-next-line
  const id = useParams().rouletteId;
  // eslint-disable-next-line
  const location = useLocation();
  const [exchangedList, setExchangedList] = useState([]);
  // eslint-disable-next-line
  const [shippedList, setShippedList] = useState([]);
  // eslint-disable-next-line
  const [exchangedAmount, setExchangedAmount] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("resultList")) {
      props.setResultList(JSON.parse(localStorage.getItem("resultList")));
      localStorage.removeItem("resultList");
    }
    if (localStorage.getItem("footerNum")) {
      props.setFooterNum(JSON.parse(localStorage.getItem("footerNum")));
      localStorage.removeItem("footerNum");
    }
    if (localStorage.getItem("navigateFromGachaResult")) {
      localStorage.removeItem("navigateFromGachaResult");
    }
  }, []);

  useEffect(() => {
    const sum = exchangedList.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.value;
    }, 0);
    setExchangedAmount(sum);
  }, [exchangedList]);

  async function exchangeAction(contents) {
    const contentIds = contents.map((content) => content.id);
    try {
      const { targetCardList } = await exchange(contentIds);
      setExchangedList(targetCardList);
      alert("ポイント還元に成功しました。");
      window.location.reload();
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
  }

  async function shipmentAction(contents) {
    const contentIds = contents.map((content) => content.id);
    try {
      const { targetCardList } = await requestShipment(contentIds);
      setShippedList(targetCardList);
      alert("カード発送に成功しました。");
      window.location.reload();
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
  }

  return (
    <div
      className={clsx(
        "px-4 fixed bottom-0 max-w-[540px] w-full rounded-t-[16px] flex justify-center left-1/2 transform -translate-x-1/2 bg-gray-300 z-50"
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {props.footerNum === 0 && (
        <div className={clsx("w-full py-[32px] flex flex-col")}>
          <div className="flex justify-between items-center">
            <p className={clsx("font-bold text-[16px] text-jgs-red")}>
              {props.selectedItems.length}/{props.resultList.length}枚を選択中
            </p>
            {props.resultList.length === 0 ||
            props.selectedItems.length === 0 ? (
              <button
                className="w-[144px] h-[46px] text-[14px] font-bold py-2 px-4 rounded-full"
                disabled
                style={{
                  backgroundColor: "rgba(253, 224, 70, 0.40)",
                  color: "rgba(0, 0, 0, 0.40)",
                }}
              >
                カードを発送依頼
              </button>
            ) : (
              <button
                className="w-[144px] h-[46px] text-[14px] bg-jgs-btn-shipment text-black font-bold py-2 px-4 rounded-full cursor-pointer"
                onClick={() => {
                  if (props.selectedItems.length === 0) {
                    alert("発送する商品を選択してください。");
                    return;
                  }
                  if (
                    props.resultList.length === 0 &&
                    props.selectedItems.length === 0
                  ) {
                    alert("発送対象の商品がありません。");
                    return;
                  }
                  props.setFooterNum(3);
                }}
              >
                カードを発送依頼
              </button>
            )}
          </div>
          <div className="my-4 w-full bg-gray-400" style={{ height: "1px" }} />
          <div className="flex justify-between items-center">
            <div className="flex flex-col justify-between items-start">
              <p className={clsx("text-[12px] font-[300] text-black")}>
                発送しない商品は全てポイント還元されます
              </p>
              <p className={clsx("text-[14px] font-bold text-black")}>
                還元ポイント
                <span className="text-[24px] mx-2">
                  {props.totalValOfselectedItems}
                  <span className={clsx("text-[14px]")}>Pt</span>
                </span>
                <span className="text-[12px] text-gray-500">
                  ({props.selectedItems.length}/{props.resultList.length}枚分)
                </span>
              </p>
            </div>
            <button
              className="bg-black hover:bg-jgs-main-hovered text-yellow-300 font-bold py-2 px-4 rounded-full"
              style={{
                fontSize: "14px",
                maxWidth: "186px",
                width: "100%",
              }}
              onClick={() => {
                if (props.resultList.length === 0) {
                  alert("還元対象の商品がありません。");
                  return;
                }
                props.selectAllCard();
                props.setFooterNum(1);
              }}
            >
              全てポイントに還元する
            </button>
          </div>

          {/* 
            <div className={clsx("mb-2")}>
              <div className={clsx("text-start text-xs text-gray-500")}>
                郵送先
              </div>
              <EditingAddressButton
                resultList={resultList}
                id={id}
                className={undefined}
              />
            </div>
          */}
        </div>
        // <div className={clsx("max-w-[540px] w-full py-6")}>
        //   {/*  */}
        //   <div className="flex items-center">
        //     <div className="w-1/3 flex flex-col items-start">
        //       <p className={clsx("text-xs text-gray-500 m-0 font-bold")}>
        //         還元ポイント
        //       </p>
        //       <h1 className={clsx("text-xl font-bold")}>
        //         {props.totalValOfselectedItems} pt
        //       </h1>
        //       <p className={clsx("m-0 font-bold text-xs text-gray-500")}>
        //         {props.selectedItems.length} / {props.resultList.length} 枚
        //       </p>
        //     </div>
        //     <div className="w-2/3 flex flex-col">
        //       <div className={clsx("mb-2")}>
        //         <div className={clsx("text-start text-xs text-gray-500")}>
        //           郵送先
        //         </div>
        //         <EditingAddressButton />
        //       </div>
        //     </div>
        //   </div>
        //   {/*  */}
        //   <div className="px-6 py-4 flex flex-col items-center gap-4">
        //     <button
        //       className="w-56 bg-black hover:bg-jgs-main-hovered text-yellow-300 font-bold py-2 px-4 rounded-full"
        //       onClick={() => {
        //         if (
        //           props.resultList.length === 0 &&
        //           props.selectedItems.length === 0
        //         ) {
        //           alert("還元対象の商品がありません。");
        //           return;
        //         }
        //         props.selectAllCard();
        //         props.setFooterNum(1);
        //       }}
        //     >
        //       全てポイントに還元する
        //     </button>
        //     <button
        //       className="w-56 bg-blue-800 text-white font-bold py-2 px-4 rounded-full"
        //       onClick={() => {
        //         if (
        //           props.resultList.length === 0 &&
        //           props.selectedItems.length === 0
        //         ) {
        //           alert("発送対象の商品がありません。");
        //           return;
        //         }
        //         if (props.selectedItems.length === 0) {
        //           alert("発送対象の商品が未選択です。");
        //           return;
        //         }
        //         props.setFooterNum(3);
        //       }}
        //     >
        //       発送する
        //     </button>
        //   </div>
        // </div>
      )}
      {props.footerNum === 1 && (
        <PointConfirm
          setFooterNum={props.setFooterNum}
          totalValue={props.totalValOfselectedItems}
          totalNum={props.resultList.length}
          selectedNum={props.selectedItems.length}
          // removeSelectedCard={props.removeSelectedCard}
          exchangeFunc={() => {
            exchangeAction(props.resultList);
          }}
        />
      )}
      {props.footerNum === 2 && (
        <PointComplete
          setFooterNum={props.setFooterNum}
          setNeedToUpdateCardList={props.setNeedToUpdateCardList}
        />
      )}
      {props.footerNum === 3 && (
        <ShipmentConfirm
          setFooterNum={props.setFooterNum}
          resultList={props.resultList}
          selectedItems={props.selectedItems}
          selectedNum={props.selectedItems.length}
          removeSelectedCard={props.removeSelectedCard}
          footerNum={props.footerNum}
          shipFunc={() => {
            shipmentAction(props.selectedItems);
          }}
        />
      )}
      {props.footerNum === 4 && (
        <ShipmentComplete
          setFooterNum={props.setFooterNum}
          setNeedToUpdateCardList={props.setNeedToUpdateCardList}
        />
      )}
    </div>
  );
}

export default HistoryFooter;
