import { atom } from "jotai";

/**
 * 住所の状態
 */
export const addressesState = atom([
  // {
  //   firstName: "太郎",
  //   lastName: "山田",
  //   firstNameKana: "タロウ",
  //   lastNameKana: "ヤマダ",
  //   postalCode: "1234567",
  //   prefecture: "東京都",
  //   addressDesc: "架空市夢見町1-23-45 夢のマンション 801号室",
  //   phoneNumber: "09000000000",
  //   isSelected: true,
  // },
  // {
  //   firstName: "太郎",
  //   lastName: "山田",
  //   firstNameKana: "タロウ",
  //   lastNameKana: "ヤマダ",
  //   postalCode: "1234567",
  //   prefecture: "東京都",
  //   addressDesc: "架空市夢見町1-23-45 夢のマンション 801号室",
  //   phoneNumber: "09000000000",
  //   isSelected: false,
  // },
]);
