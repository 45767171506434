import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { isOpenMyPageState } from "../../stores/isOpenMyPageState";
import { onAuthStateChanged } from "firebase/auth";
import { fireauth } from "../../package/firebase";
import JGSLoaing from "../../components/JGSLoaing";
import logoJGS from "../../assets/JGS_logo1.png";
import Mask from "../../components/Mask";
import { MdOutlineClose } from "react-icons/md";
import { useAuthState } from "react-firebase-hooks/auth";
import MyCarousel from "../../components/roulette/MyCarousel.jsx";
import campaign from "../../assets/campaign.png";

export function RouletteIndex(props) {
  const navigate = useNavigate();
  const isOpenMyPage = useSetAtom(isOpenMyPageState);
  // eslint-disable-next-line no-unused-vars
  const [user, loading] = useAuthState(fireauth);
  const gachaList = props.gachaList;
  const hitList = props.hitList;
  const [proppedHitList, setProppedHitList] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOpenPRModal, setIsOpenPRModal] = useState(false);

  useEffect(() => {
    const listener = onAuthStateChanged(fireauth, async (u) => {
      setIsAuthenticated(!!u);
    });

    return () => {
      listener();
    };
  }, []);

  useEffect(() => {
    if (loading) return;
    !user ? setIsOpenPRModal(true) : setIsOpenPRModal(false);
  }, [user, loading]);

  useEffect(() => {
    const bodyElement = document.body;
    if (isOpenPRModal) {
      bodyElement.classList.add("overflow-hidden");
    } else {
      bodyElement.classList.remove("overflow-hidden");
    }

    return () => {
      bodyElement.classList.remove("overflow-hidden");
    };
  }, [isOpenPRModal]);

  useEffect(() => {
    isOpenMyPage(false);
  }, [isOpenMyPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setProppedHitList(hitList);
  }, [hitList]);

  const goDetail = (id) => {
    navigate(`/roulette/${id}`);
  };

  const closeModal = () => {
    setIsOpenPRModal(false);
  };

  function ProgressBar({ id }) {
    const allItems = proppedHitList[id] && proppedHitList[id].length;
    const Inventory =
      proppedHitList[id] &&
      proppedHitList[id].filter(
        (item) => !item.holderId || item.holderId === ""
      ).length;
    const progress = Inventory / allItems / 0.01;
    return (
      <div className="relative pt-1 w-[144px]">
        <div className="flex items-center justify-center">
          <span className="text-[12px] font-semibold inline-block text-jgs-main">
            のこり {Inventory} / {allItems}
          </span>
        </div>
        <div className="overflow-hidden h-2 mt-2 mb-1 text-xs flex rounded bg-gray-200">
          <div
            style={{ width: `${progress}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-jgs-main"
          ></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen py-4 background-diagonal-lines">
        {!isAuthenticated && proppedHitList.length === 0 && <JGSLoaing />}
        {/* カルーセル */}
        <MyCarousel
          currentPoint={props.currentPoint}
          setCurrentPoint={props.setCurrentPoint}
          setLoadingForYT={props.setLoadingForYT}
        />
        {/* Tags */}
        <div className="max-w-[768px] w-full mx-auto flex justify-center">
          <div className="my-2 pb-6 px-2 flex gap-4 overflow-x-auto whitespace-nowrap">
            {props.tagList.map((tagName, index) => (
              <div
                key={index}
                className={clsx(
                  "px-4 font-bold rounded-xl flex-none cursor-pointer",
                  props.selectedTag === tagName ? "bg-yellow-300" : "bg-white"
                )}
                onClick={() => props.setSelectedTag(tagName)}
              >
                {tagName}
              </div>
            ))}
          </div>
        </div>
        {/* Contents */}
        <div className="max-w-[768px] mx-auto p-4 grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 justify-items-center place-items-start">
          {gachaList.map((gacha, index) => (
            <div
              key={index}
              className="p-[16px] w-[336px] h-[382px] rounded-xl bg-white shadow-lg border hover:border-blue-500"
            >
              <div
                onClick={() => goDetail(gacha.id)}
                className="rounded-[16px] overflow-hidden w-[300px] h-[300px]"
              >
                <img
                  className="object-cover h-full w-full"
                  src={gacha.img}
                  alt="package image"
                ></img>
              </div>
              <div className="mt-4 flex justify-between items-center">
                <p className="flex justify-start items-end leading-none text-[24px] font-[800] w-full max-w-[144px]">
                  {gacha.price}
                  <span className="text-[14px] font-[600]">Pt/1回</span>
                </p>
                <ProgressBar id={gacha.id} />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Footer */}
      <div className="bg-slate-700 text-white">
        <div className="max-w-[1050px] mx-auto p-4 flex flex-col items-start">
          <h1 className="text-lg mb-2 font-bold">リボルヴについて</h1>
          <a
            href="https://jgs-yokohama.com/company/"
            target="_blank"
            rel="noreferrer"
            className="mb-1 hover:underline inline"
          >
            運営会社
          </a>
          <a
            href="https://jgs-yokohama.com/company/"
            target="_blank"
            rel="noreferrer"
            className="mb-1 hover:underline inline"
          >
            利用規約
          </a>
          <a
            href="https://jgs-yokohama.com/company/"
            target="_blank"
            rel="noreferrer"
            className="mb-1 hover:underline"
          >
            特定商取引法に基づく表記
          </a>
          <a
            href="/google-privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="mb-1 hover:underline inline"
          >
            Googleプライバシーポリシー
          </a>
          <a
            href="https://jgs-yokohama.com/contact/"
            target="_blank"
            rel="noreferrer"
            className="mb-2 hover:underline"
          >
            お問い合わせ
          </a>
          <img src={logoJGS} className="w-12 h-12" alt="logo" />
        </div>
      </div>

      {isOpenPRModal && (
        <Mask closeModal={closeModal}>
          <div
            className={clsx(
              "w-5/6 min-w-[327px] max-w-[600px] px-[24px] py-[32px] rounded-xl",
              "bg-white shadow-[-2px_0_5px_rgba(0,0,0,0.1)]",
              "flex flex-col",
              "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
              "max-h-[90vh] overflow-auto"
            )}
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          >
            <div className="flex justify-end w-full">
              <MdOutlineClose
                className="w-5 h-5 ml-2 text-gray-500"
                onClick={(e) => {
                  e.stopPropagation();
                  closeModal();
                }}
              />
            </div>

            <img
              src={campaign}
              className="w-full object-contain"
              alt="campaign image"
            />
          </div>
        </Mask>
      )}
    </>
  );
}
