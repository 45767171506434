import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../package/firebase";

export async function imgUpload(img) {
  const unixtime = Date.now();
  const storageRef = ref(storage, unixtime.toString());

  // ファイルをFirebase Storageにアップロード
  const uploadTask = uploadBytesResumable(storageRef, img);

  // Promiseを返すことで非同期処理を適切に扱う
  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // アップロードの進行状況を監視
        // eslint-disable-next-line
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        // エラー処理
        console.error(error);
        reject(null); // エラーが発生した場合はPromiseをrejectする
      },
      async () => {
        // アップロード完了後の処理
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL); // ダウンロードURLを取得したらPromiseをresolveする
        } catch (error) {
          console.error(error);
          reject(null); // getDownloadURLでエラーが発生した場合はPromiseをrejectする
        }
      }
    );
  });
}
