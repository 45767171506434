import clsx from "clsx";
import React from "react";

/**
 * ローディング中にくるくる回るやつ
 */
export const Spinner = (props) => {
  const { className, ...otherProps } = props;

  return (
    <div
      className={clsx(
        "animate-spin",
        "rounded-full",
        "border-t-transparent",
        className
      )}
      {...otherProps}
    />
  );
};
