import { getCurrentUser } from "./auth";
import { firebaseFirestore } from "../package/firebase";
import { doc, getDoc } from "firebase/firestore";
import { BACKEND_URL, PRICES } from "../const/const";
import {
  INVALID_POINT_PRICE_MSG,
  NOT_LOGINED_MSG,
} from "../const/error-message";

export const createPaymentIntent = async (price) => {
  const parsedPrice = parseInt(price, 10);
  if (!PRICES.includes(parsedPrice)) throw new Error(INVALID_POINT_PRICE_MSG);

  const user = await getCurrentUser();
  if (!user) throw new Error(NOT_LOGINED_MSG);

  const token = await user.getIdToken();

  const res = await fetch(
    `${BACKEND_URL}/create-payment-intent/${parsedPrice}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user.uid,
      }),
    }
  );

  const { message, data } = await res.json();
  if (res.status !== 200) throw new Error(message || res.statusText);

  return data;
};

export async function point(point) {
  const user = await getCurrentUser();
  if (user === null) {
    return false;
  }
  let result = false;
  try {
    await fetch(`/add-point`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        point: point,
        userId: user.uid,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        result = data;
      });
  } catch (error) {
    console.error(error);
    result = false;
  }
  return result;
}

export async function getPoint(uid) {
  try {
    const usersRef = doc(firebaseFirestore, "users", uid);
    const userDoc = await getDoc(usersRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return userData.point;
    } else {
      console.error("該当するユーザーが見つかりませんでした");
      return 0;
    }
  } catch {
    console.error("ユーザー情報の取得中にエラーが発生しました");
    return 0;
  }
}
