import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../actions/auth";
import clsx from "clsx";
import logoJGS from "../assets/JGS_logo1.png";
// import sampleEvent from "../assets/sample-event.jpg";

export function OurSimplifiedColoredHeader() {
  // eslint-disable-next-line
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function getProfile() {
      const user = await getCurrentUser();
      // const point = await getPoint();
      if (user === null) {
        return;
      }
      setUser(user);
    }
    getProfile();
  }, []);

  const navigate = useNavigate();

  return (
    <header className={clsx("w-full p-2", "bg-jgs-main z-30")}>
      <div className={clsx("max-w-[1060px] mx-auto flex flex-col")}>
        <div className={clsx("cursor-pointer", "text-2xl")}>
          <img
            src={logoJGS}
            className={clsx("w-10 h-10")}
            alt="logo"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
    </header>
  );
}
