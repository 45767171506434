import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../actions/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { checkUsersItems } from "../../actions/auth";
import { ERROR_MSG_PREFIX } from "../../const/error-message";
import { exchange } from "../../actions/backend";

export function Profile() {
  const [user, setUser] = useState({
    uid: "",
    displayName: "",
    email: "",
    photoURL: "",
    point: 0,
  });
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user === null) return;
    setUser(user);
  });
  const [items, setItems] = useState([]);
  const [exchanged, setExchanged] = useState([]);
  async function exchangeAction(content) {
    const contentIds = [content.id];
    try {
      const { targetCardList } = await exchange(contentIds);
      setExchanged([...exchanged, ...targetCardList]);
    } catch (error) {
      alert(`${ERROR_MSG_PREFIX} ${error.message}`);
      return;
    }
  }
  useEffect(() => {
    async function getProfile() {
      const user = await getCurrentUser();
      if (user) {
        const items = await checkUsersItems(user);
        setItems(items);
      }
      if (user === null) {
        return;
      }
      setUser(user);
    }
    getProfile();
  }, []);
  return (
    <div>
      <h1>マイページ</h1>
      <div>
        <img src={user.photoURL} alt="プロフィール画像" />
      </div>
      <div>
        <h2>名前：{user.displayName}</h2>
        <h2>メールアドレス：{user.email}</h2>
      </div>
      <div>
        <h2>獲得アイテム</h2>
        {items.length === 0 && <p>まだアイテムを獲得していません</p>}
        {items.length !== 0 && (
          <ul>
            {items.map((item) => {
              return (
                <li key={item.id}>
                  <img
                    width="189"
                    height="264"
                    src={item.img}
                    alt="アイテム画像"
                  />
                  <br></br>
                  {item.value}ポイント
                  {item.status === "exchanged" && <p>交換済み</p>}
                  {item.status === "yet" &&
                    !exchanged.some((ex) => ex.id === item.id) && (
                      <button
                        onClick={() => {
                          exchangeAction([item]);
                        }}
                      >
                        交換する
                      </button>
                    )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}
